import React from "react";
// import { Link } from "gatsby";

import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

function CardArticle({
    articleClass,
    articleHeading,
    articleSubHeading,
    articleDesc,
    articleBtn,
    ...props
}) {
    return (
        <Popup trigger={
            <article {...props} className={`card-article ${articleClass ? articleClass : ""}`}>
                {articleSubHeading ? (
                    <div dangerouslySetInnerHTML={{ __html: articleSubHeading }} />
                ) : ""}
                {articleHeading ? (
                    <div dangerouslySetInnerHTML={{ __html: articleHeading }} />
                ) : ""}
                {articleBtn ? (
                    <span className="btn btn-secondary">{articleBtn}</span>
                ) : ""}
            </article>
        } modal>
            {close => (
                <div className="modal card-article__modal">
                    <div className="modal-header text-right">
                        <button className="close" onClick={close}>
                            &times;
                        </button>
                    </div>
                    <div
                        {...props} className="modal-body">
                        {articleSubHeading ? (
                            <div dangerouslySetInnerHTML={{ __html: articleSubHeading }} />
                        ) : ""}
                        {articleHeading ? (
                            <div dangerouslySetInnerHTML={{ __html: articleHeading }} />
                        ) : ""}
                        {articleDesc ? (
                            <div dangerouslySetInnerHTML={{ __html: articleDesc }} />
                        ) : ""}
                    </div>
                </div>
            )}

        </Popup>
    );
}

export default CardArticle;
