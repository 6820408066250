import React from 'react'
import Select from '../../custom/select';
import Option from '../../custom/select/option';
import '../filter-tabs.scss'

const TAB_NAME = 'filter-tabs'

const Tabs = React.forwardRef(function Tabs(props, ref) {
  const {
    'aria-label': ariaLabel,
    'aria-labelledby': ariaLabelledBy,
    action,
    centered = false,
    className = "",
    children,
    onChange,
    orientation = 'horizontal',
    tabControlName,
    value,
    visibleScrollbar = false,
    ...rest
  } = props;

  let childIndex = 0;
  const Children = React.Children.map(children, (child) => {
    if (!React.isValidElement(child)) {
      return null;
    }

    const childValue = child.props.value === undefined ? childIndex : child.props.value;
    const selected = childValue === value;

    childIndex++;
    return React.cloneElement(child, {
      selected,
      onChange,
      value: childValue,
      tabControlName,
      ...(childIndex === 1 && value === false && !child.props.tabIndex ? { tabIndex: 0 } : {}),
    });
  });

  return (
    <React.Fragment>
      <div
        {...rest}
        ref={ref}
        className={`${TAB_NAME}__tabs ${className}`}
        aria-label={ariaLabel}
        aria-labelledby={ariaLabelledBy}
        aria-orientation={orientation === 'vertical' ? 'vertical' : null}
        role='tablist'
      >
        {Children}
      </div>
      {Children.length > 0 &&
        <Select onChange={e => onChange({}, e)}>
          {Children.map((item) => (
            <Option
              key={item.props.label}
              value={item.props.value}
              selected={item.props.selected}
            >
              {item.props.label}
            </Option>
          ))}
        </Select>
      }
    </React.Fragment>
  )
})

export default Tabs