import React, { useState } from "react";
import { Col, Row } from "react-grid-system";

import Tabs from "./filterTab/tabs";
import Tab from "./filterTab/tab";
import TabPanel from "./filterTab/tab-panel";
import Select from "./custom/select";
import Option from "./custom/select/option";
import RecentBlogPosts from "./RecentBlogPosts";
import ContentWrap from "./content-wrapper";
import { useBlogPost } from "../hooks/useBlogPost";

const initialDataToShow = 3;

function AllBlogPosts() {
  const wpData = useBlogPost();
  const data = wpData.allWpPost.edges ?? [];

  const [tab, setTab] = useState("");
  const [sort, setSort] = useState("");
  const [dataToshow, setDataToShow] = useState(initialDataToShow);
  const [news, setNews] = useState([]);

  const handleTabChange = (e, tab) => {
    setTab(tab);
    setSort("");
    setDataToShow(initialDataToShow);

    if (tab) {
      const filteredData = data.filter((item) => {
        const category = item.node?.categories?.nodes ?? [];
        return category.some((cat) => cat.slug === tab);
      });
      setNews([...filteredData]);
    } else {
      setNews([...data]);
    }
  };

  const handleSortChange = (value) => {
    setSort(value);
  };

  const handleLoadMore = () => {
    setDataToShow(dataToshow + initialDataToShow);
  };

  let filteredData = news;
  if (!sort) {
  } else if (sort === "oldest") {
    filteredData = filteredData.sort((a, b) => {
      return new Date(a.node.date) - new Date(b.node.date);
    });
  } else if (sort === "newest") {
    filteredData = filteredData.sort((a, b) => {
      return new Date(b.node.date) - new Date(a.node.date);
    });
  } else {
    filteredData = filteredData.filter((item) => {
      const category = item.node?.categories?.nodes ?? [];
      return category.some((cat) => cat.slug === sort);
    });
  }

  let slicedData = filteredData.slice(0, dataToshow);
  console.log("deploy test");
  return (
    <div className="all-blog-posts">
      <div className="filter-tabs__wrap">
        <Row className="filter-tab-row" align="center">
          <Col lg={8}>
            <Tabs onChange={handleTabChange} value={tab}>
              <Tab label="All" value="" />
              <Tab label="Paragon News" value="paragon" />
              <Tab label="Partner News" value="partner" />
            </Tabs>
          </Col>
          <Col lg={4}>
            {tab === "paragon" ? (
              <Select
                className="select-date"
                onChange={handleSortChange}
                value={sort}
              >
                <Option value={""} selected>
                  Sort by
                </Option>
                <Option value={"newest"}>Newest</Option>
                <Option value={"oldest"}>Oldest</Option>
              </Select>
            ) : tab === "partner" ? (
              <Select
                className="select-date"
                onChange={handleSortChange}
                value={sort}
              >
                <Option value={""} selected>
                  Sort by
                </Option>
                <Option value={"newest"}>Newest</Option>
                <Option value={"oldest"}>Oldest</Option>
                <Option value={"spyre"}>Spyre Therapeutics</Option>
                <Option value={"apogee"}>apogee Therapeutics</Option>
                <Option value={"okura"}>Oruka Therapeutics</Option>
                <Option value={"jade"}>Jade Biosciences</Option>
                <Option value={"crescent"}>Crescent Biopharma</Option>
              </Select>
            ) : (
              <Select
                className="select-date"
                onChange={handleSortChange}
                value={sort}
              >
                <Option value={""} selected>
                  Sort by
                </Option>
                <Option value={"newest"}>Newest</Option>
                <Option value={"oldest"}>Oldest</Option>
                <Option value={"paragon"}>Paragon Therapeutics</Option>
                <Option value={"spyre"}>Spyre Therapeutics</Option>
                <Option value={"apogee"}>apogee Therapeutics</Option>
                <Option value={"okura"}>Oruka Therapeutics</Option>
                <Option value={"jade"}>Jade Biosciences</Option>
                <Option value={"crescent"}>Crescent Biopharma</Option>
              </Select>
            )}
          </Col>
        </Row>
      </div>
      <TabPanel index={""} value={tab}>
        <Row className="row">
          <Col lg={7.5}>
            <RecentBlogPosts data={slicedData} />
          </Col>
        </Row>
      </TabPanel>
      <TabPanel index={"paragon"} value={tab}>
        <Row className="row">
          <Col lg={7.5}>
            <RecentBlogPosts data={slicedData} />
          </Col>
        </Row>
      </TabPanel>
      <TabPanel index={"partner"} value={tab}>
        <Row className="row">
          <Col lg={7.5}>
            <RecentBlogPosts data={slicedData} />
          </Col>
        </Row>
      </TabPanel>

      {filteredData.length > dataToshow && (
        <ContentWrap linkText="LOAD MORE" onClick={handleLoadMore} />
      )}
    </div>
  );
}

export default AllBlogPosts;
