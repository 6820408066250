import React from "react";
import CardArticle from "./card-article";

const RecentBlogPosts = ({ data = [] }) => {

  return (
    <>
      {data.map((post, index) => {
        let blog = post.node;
        return (
          <CardArticle
            articleClass="title-block"
            articleSubHeading={"<h3>" + blog.date + "</h3>"}
            articleHeading={"<h2>" + blog.title + "</h2>"}
            articleDesc={blog.content}
            articleBtn="Read More"
            key={blog.title + index}
          />
        );
      })}
    </>
  );
};

export default RecentBlogPosts;
