import React from 'react'
import '../filter-tabs.scss'

const TAB_NAME = 'filter-tabs'

const Tab = React.forwardRef(function Tab(props, ref) {
  const {
    className = '',
    disabled = false,
    onChange,
    tabControlName = 'simple-tabs',
    onClick,
    selected,
    label,
    value,
    ...rest
  } = props;

  const handleClick = (e) => {
    !selected && onChange && onChange(e, value)
    onClick && onClick(e)
  }

  return (
    <button
      {...rest}
      className={`${TAB_NAME}__tab ${className} ${selected ? `${TAB_NAME}__tab-selected` : ''}`}
      ref={ref}
      role="tab"
      id={`${tabControlName}-tab-${value}`}
      aria-controls={`${tabControlName}-panel-${value}`}
      aria-selected={selected}
      disabled={disabled}
      onClick={handleClick}
      tabIndex={selected ? 0 : -1}
    >
      <span>{label}</span>
    </button>
  )
})

export default Tab