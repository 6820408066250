import {graphql, useStaticQuery} from "gatsby";

export const useBlogPost = () => {
    return useStaticQuery(graphql`
        query LatestBlogPostQuery {
            allWpPost(sort: {fields: date, order: DESC}, ) {
                edges {
                    node {
                        title
                        content
                        excerpt
                        uri
                        date(formatString: "MM.DD.YYYY")
                        categories {
                            nodes {
                                name
                                slug
                            }
                        }
                    }
                }
            }
        }`);
}