import * as React from "react";
import { useEffect, useState, useRef } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import FluidContent from "../components/fluid-content";
import CardIcon from "../components/card-icon";
import ContentWrap from "../components/content-wrapper";
import TeamDetails from "../components/team-details";
//import CardArticle from "../components/card-article";
import { Col, Container, Row } from "react-grid-system";
// import { Link } from "gatsby";
import { useForm } from "react-hook-form";
import ReCAPTCHA from "react-google-recaptcha";
import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import heroBg from "../images/hero-bg.webp";
import heroBgMobile from "../images/hero-bg-mobile.webp";
import scienceBg from "../images/science-bg.webp";
import squareIcon from "../images/square-icon.svg";
import squareIcon2 from "../images/square-icon-2.svg";
import squareIcon3 from "../images/square-icon-3.svg";
import squareIconCheck from "../images/square-icon-check.svg";
import fairmountLogo from "../images/fairmount-logo.png";
import patternBg from "../images/pattern-bg.webp";
import patternBgMobile from "../images/pattern-bg-mobile.webp";
import joinBg from "../images/join-bg-01.webp";
//import joinBgMobile from "../images/join-bg-mobile.png";
import apogeeLogo from "../images/apogee-logo.svg";
import plusIcon from "../images/plus-icon.svg";
import minusIcon from "../images/minus-icon.svg";
import apogeeLargeLogo from "../images/apogee-large-logo.webp";
import paragonLogo from "../images/paragon-logo.png";
// import arrowColored from "../images/arrow-colored.svg";
import spyreLogo from "../images/spyre-logo.png";
import spyreLargeLogo from "../images/spyre-large-logo.png";
import thompsonHeadshot from "../images/teams/thompson-headshot.webp";
import alamHeadshot from "../images/teams/alam-headshot.webp";
import pohleHeadshot from "../images/teams/pohle-headshot.webp";
import fioreHeadshot from "../images/teams/fiore-headshot.webp";
import banksHeadshot from "../images/teams/banks-headshot.webp";
import batschaHeadshot from "../images/teams/batscha-headshot.webp";
import gillisHeadshot from "../images/teams/gillis-headshot.webp";
// import greenbergHeadshot from "../images/teams/greenberg-headshot.webp";
import schirmer from "../images/teams/schirmer-headshot.webp";
import kangHeadshot from "../images/teams/kang-headshot.webp";
//import kelleyHeadshot from "../images/teams/kelley-headshot.webp";
import markHeadshot from "../images/teams/mark-headshot.webp";
import kramerHeadshot from "../images/teams/kramer-headshot.webp";
import kwanHeadshot from "../images/teams/kwan-headshot.webp";
import lantzHeadshot from "../images/teams/lantz-headshot.webp";
import danielleHeadshot from "../images/teams/danielle-headshot.webp";
// import liuHeadshot from "../images/teams/liu-headshot.webp";
import medinaHeadshot from "../images/teams/medina-headshot.webp";
import meehlHeadshot from "../images/teams/meehl-headshot.webp";
import milliganHeadshot from "../images/teams/milligan-headshot.webp";
import melissa from "../images/teams/donnarummo-headshot.webp";
import ministroHeadshot from "../images/teams/ministro-headshot.webp";
//import nzukieHeadshot from "../images/teams/nzukie-headshot.webp";
import ohHeadshot from "../images/teams/oh-headshot.webp";
import oliveiraHeadshot from "../images/teams/oliveira-headshot.webp";
//import petrosHeadshot from "../images/teams/petros-headshot.webp";
import riosHeadshot from "../images/teams/rios-headshot.webp";
import pamelaHeadshot from "../images/teams/pamela-headshot.webp";
import russellHeadshot from "../images/teams/russell-headshot.webp";
import susannaHeadshot from "../images/teams/susanna-headshot.webp";
import castroHeadshot from "../images/teams/ines-castro-headshot.webp";
import ghassanHeadshot from "../images/teams/ghassan-fayad-headshot.webp";
import shaheenHeadshot from "../images/teams/shaheen-headshot.webp";
import shahHeadshot from "../images/teams/shah-headshot.webp";
import joeHeadshot from "../images/teams/joe-headshot.webp";
import staceyHeadshot from "../images/teams/stacey-headshot.webp";
import vazHeadshot from "../images/teams/vaz-headshot.webp";
import vidalHeadshot from "../images/teams/vidal-headshot.webp";
//import zhuHeadshot from "../images/teams/zhu-headshot.webp";
import maslinHeadshot from "../images/teams/lauren-headshot.webp";
import clarkHeadshot from "../images/teams/erin-headshot.webp";
import rayeHeadshot from "../images/teams/jodie-headshot.webp";
import igoeHeadshot from "../images/teams/jill-headshot.webp";
import bernardHeadshot from "../images/teams/bernard-headshot.webp";
import orukaFullLogo from "../images/oruka-logo.svg";
import crescentLogo from "../images/crescent-logo.webp";
import jadeBiosciencesLogo from "../images/jade-biosciences-logo.svg";

import "./index.scss";
// import RecentBlogPosts from "../components/RecentBlogPosts";
import AllBlogPosts from "../components/allBlogPosts";

const FORM_GATEWAY_URL = process.env.GATSBY_FORM_GATEWAY_URL;
const RECAPTCHA_SITE_KEY = process.env.GATSBY_RECAPTCHA_SITE_KEY;
//const RECAPTCHA_SITE_KEY = "Stop Error";

//const recaptchaContainerId= 'recaptcha-container'

const IndexPage = () => {
  const [submitted, setSubmitted] = useState(false);
  const [activeItem, setActiveItem] = useState([]);
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const handleItemOpen = (index) => {
    activeItem.push(index);
    setActiveItem([...activeItem]);
  };
  const handleItemClose = (index) => {
    const i = activeItem.indexOf(index);
    if (i > -1) {
      activeItem.splice(i, 1);
    }
    setActiveItem([...activeItem]);
  };

  const {
    register,
    setValue,
    handleSubmit,
    clearErrors,
    setError,
    reset,
    formState: { errors },
  } = useForm();

  const recaptchaRef = useRef(null);

  const onRecaptchaChange = (value) => {
    setRecaptchaToken(value);
    setValue("token", value);
    clearErrors("token");
    //document.getElementById("recaptcha-token-input")?.value = value;
  };

  const onSubmit = async (data) => {
    data.token = recaptchaToken;
    fetch(FORM_GATEWAY_URL, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      redirect: "follow",
      body: JSON.stringify(data),
    })
      .then((res) => {
        if (res.status >= 400 && res.status < 600) {
          setError("submit", {
            type: "string",
            message: "Oops! There seems to be an issue!",
          });
        } else {
          setSubmitted(true);
          reset();
        }
      })
      .catch((error) => {
        setError(
          "submit",
          "submitError",
          `Oops! There seems to be an issue! ${error.message}`
        );
      });
  };

  const showSubmitError = (msg) => <p className="error-message">{msg}</p>;

  const showThankYou = (
    <div className="msg-confirm">
      <div className="msg-confirm__img">
        <img src={squareIconCheck} alt="Checkmark" />
      </div>
      <div className="msg-confirm__text">
        <h2>Thank you.</h2>
        <p>Your message was sent.</p>
      </div>
    </div>
  );

  const showForm = (
    <form
      className="contact-block__form"
      onSubmit={handleSubmit(onSubmit)}
      method="post"
    >
      <div className="contact-block__group contact-block--full">
        {errors && errors.submit && showSubmitError(errors.submit.message)}
      </div>

      <div className="contact-block__group">
        <label>First Name*</label>
        <input type="text" {...register("first_name", { required: true })} />
        {errors?.first_name?.type === "required" && (
          <p className="error-message">First name is required</p>
        )}
      </div>
      <div className="contact-block__group">
        <label>Last Name*</label>
        <input type="text" {...register("last_name", { required: true })} />
        {errors?.last_name?.type === "required" && (
          <p className="error-message">Last name is required</p>
        )}
      </div>
      <div className="contact-block__group contact-block--full">
        <label>Email Address*</label>
        <input
          type="text"
          {...register("email", { required: true, pattern: /^\S+@\S+$/i })}
        />
        {errors?.email?.type === "required" && (
          <p className="error-message">Enter a valid e-mail address</p>
        )}
        {errors?.email?.type === "pattern" && (
          <p className="error-message">Enter a valid e-mail address</p>
        )}
      </div>
      <div className="contact-block__group contact-block--full">
        <label>Message*</label>
        <textarea {...register("message", { required: true })} />
        {errors?.message?.type === "required" && (
          <p className="error-message">Message is required</p>
        )}
      </div>

      <div className="contact-block__group contact-block--full">
        <ReCAPTCHA
          sitekey={RECAPTCHA_SITE_KEY}
          ref={recaptchaRef}
          onChange={onRecaptchaChange}
        />
        <input
          type="hidden"
          id={"recaptcha-token-input"}
          {...register("token", { required: true })}
        />
        {errors?.token?.type === "required" && (
          <p className="error-message">
            Please complete the verification challenge.
          </p>
        )}
      </div>

      <div className="contact-block__group contact-block--btn">
        <button type="submit" className="btn">
          Send Message
        </button>
      </div>
    </form>
  );

  const fadeRef = useRef([]);
  fadeRef.current = [];
  const fadeRef1 = useRef([]);
  fadeRef1.current = [];
  const valuesRef = useRef(null);
  const valuesRef2 = useRef(null);
  const valuesRef3 = useRef(null);
  const valuesRef4 = useRef(null);
  const valuesRef5 = useRef(null);

  const valuesRef6 = useRef(null);
  const fadeToRefs = (fade) => {
    if (fade) {
      fadeRef.current.push(fade);
    }
  };
  const fadeToRefs1 = (fade) => {
    if (fade) {
      fadeRef1.current.push(fade);
    }
  };

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);

    let mm = gsap.matchMedia();
    mm.add("(min-width: 1600px)", () => {
      gsap.from(".table-progress", {
        width: "17px",
        duration: 1,
        stagger: 0.5,
        scrollTrigger: {
          trigger: ".block-pipeline",
          start: "top",
        },
      });
    });

    mm.add("(max-width: 1599px)", () => {
      let tl = gsap.from(".table-progress", {
        duration: 1,
        stagger: 0.5,
        repeatRefresh: true,
        repeat: 0,
      });
      tl.progress(0);
      tl.invalidate();
      tl.play();
    });

    fadeRef.current.map((fade) => {
      gsap.fromTo(
        fade,
        1,
        {
          opacity: 0,
          y: 100,
        },
        {
          opacity: 1,
          y: 0,
          duration: 1.5,
          scrollTrigger: {
            trigger: fade,
          },
        }
      );
    });
    fadeRef1.current.map((fade) => {
      gsap.fromTo(
        fade,
        1,
        {
          opacity: 0,
          y: 100,
        },
        {
          opacity: 1,
          y: 0,
          duration: 1.5,
          scrollTrigger: {
            trigger: fade,
          },
        }
      );
    });

    // New animation for block-values
    gsap.fromTo(
      valuesRef.current,
      { opacity: 0, y: 100 },
      {
        opacity: 1,
        y: 0,
        duration: 1.5,
        scrollTrigger: {
          trigger: valuesRef.current,
          start: "top 80%", // Adjust the starting point as needed
        },
      }
    );

    gsap.fromTo(
      valuesRef2.current,
      { opacity: 0, y: 100 },
      {
        opacity: 1,
        y: 0,
        duration: 1.5,
        scrollTrigger: {
          trigger: valuesRef2.current,
          start: "top 80%", // Adjust the starting point as needed
        },
      }
    );

    gsap.fromTo(
      valuesRef3.current,
      { opacity: 0, y: 100 },
      {
        opacity: 1,
        y: 0,
        duration: 1.5,
        scrollTrigger: {
          trigger: valuesRef3.current,
          start: "top 80%", // Adjust the starting point as needed
        },
      }
    );
    gsap.fromTo(
      valuesRef4.current,
      { opacity: 0, y: 100 },
      {
        opacity: 1,
        y: 0,
        duration: 1.5,
        scrollTrigger: {
          trigger: valuesRef4.current,
          start: "top 80%", // Adjust the starting point as needed
        },
      }
    );
    gsap.fromTo(
      valuesRef5.current,
      { opacity: 0, y: 100 },
      {
        opacity: 1,
        y: 0,
        duration: 1.5,
        scrollTrigger: {
          trigger: valuesRef5.current,
          start: "top 80%", // Adjust the starting point as needed
        },
      }
    );

    gsap.fromTo(
      valuesRef6.current,
      { opacity: 0, y: 100 },
      {
        opacity: 1,
        y: 0,
        duration: 1.5,
        scrollTrigger: {
          trigger: valuesRef6.current,
          start: "top 80%", // Adjust the starting point as needed
        },
      }
    );
  }, []);

  return (
    <Layout pageClass="home-page">
      <main>
        <FluidContent className="banner bg-cover">
          <img
            src={heroBg}
            width="1920"
            height="1078"
            loading="lazy"
            alt="Hero"
            className="desktop-hero"
          />
          <img
            src={heroBgMobile}
            loading="lazy"
            width={780}
            height={1686}
            alt="Hero"
            className="mobile-hero"
          />
          <Container className="banner-wrap" ref={fadeToRefs}>
            <ContentWrap
              className="title-gradient"
              contentHeading="<h1>Shaping the next generation of novel treatments.</h1>"
              contentPara="<p>Paragon Therapeutics is dedicated to discovering and developing best-in-class biologics and complex biologics for patients with high unmet needs.</p>"
            />
          </Container>
        </FluidContent>

        <div id="science">
          <FluidContent className="bg-cover block-science">
            <img
              src={scienceBg}
              width="1920"
              height="1069"
              loading="lazy"
              alt="Science"
            />
            <Container>
              <Row ref={fadeToRefs}>
                <Col lg={6}>
                  <ContentWrap
                    className="title-block title-block--alt"
                    contentSubHeading="<h3>Science</h3>"
                    contentHeading="<h2>Leveraging cutting-edge science and technology to identify and propel best-in-class biologics to the clinic.</h2>"
                  />
                </Col>
                <Col lg={6}>
                  <ContentWrap
                    className="text-white margin-inline"
                    contentPara="<p>Paragon employs a breadth of protein engineering technologies to perform biologics discovery and optimization. We have built a team of exceptional leaders in drug discovery and development who work with top-tier partners to develop transformative programs.</p>"
                  />
                </Col>
              </Row>
              <div className="card-icon__block">
                <Row>
                  <Col lg={4} ref={fadeToRefs}>
                    <CardIcon
                      imgRef={squareIcon}
                      imgAlt="Science"
                      cardDesc="<p>We engineer the best drug candidates by applying high-throughput screening systems and optimization techniques.</p>"
                    />
                  </Col>
                  <Col lg={4} ref={fadeToRefs}>
                    <CardIcon
                      imgRef={squareIcon2}
                      imgAlt="Science"
                      cardDesc="<p>Our CMC model enables us to rapidly and reliably progress novel treatments from ideation to the clinic.</p>"
                    />
                  </Col>
                  <Col lg={4} ref={fadeToRefs}>
                    <CardIcon
                      imgRef={squareIcon3}
                      imgAlt="Science"
                      cardDesc="<p>Our development process allows us to successfully execute highly efficient program timelines with cutting-edge technologies while ensuring quality is never sacrificed.</p>"
                    />
                  </Col>
                </Row>
              </div>
            </Container>
          </FluidContent>
        </div>

        <div id="pipeline">
          <FluidContent className="block-pipeline">
            <Container>
              <Row>
                <Col lg={6} ref={fadeToRefs}>
                  <ContentWrap
                    className="title-block"
                    contentSubHeading="<h3>Pipeline<h3>"
                    contentHeading="<h2>Fueling a pipeline of best-in-class therapies for a range of diseases.</h2>"
                  />
                </Col>
                <Col lg={6} ref={fadeToRefs}>
                  <ContentWrap
                    className="margin-inline"
                    contentPara="<p>Our business model centers on bringing transformative therapies to patients as rapidly as possible. Our product candidate pipeline demonstrates our progress, with multiple programs in the clinic and the leading program having entered the clinic less than two years from ideation.</p>"
                  />
                </Col>
              </Row>

              <div className="table-block__wrap">
                <div
                  className={`table-block modified ${
                    activeItem.includes(0) ? "show" : ""
                  }`}
                >
                  <Container className="mobile-bg">
                    <Row className="table-wrap">
                      <Col lg={10}>
                        <Row className="table-row table-head">
                          <Col className="table-col" lg={2.4} xs={6}>
                            <h3>Programs</h3>
                          </Col>
                          <Col className="table-col mobile-none" lg={3.2}>
                            <h4>Discovery</h4>
                          </Col>
                          <Col className="table-col mobile-none" lg={3.2}>
                            <h4>Ind-Enabling</h4>
                          </Col>
                          <Col className="table-col mobile-none" lg={3.2}>
                            <h4>Clinical</h4>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={2}>
                        <Row className="table-row table-head for-desktop">
                          <Col className="table-col table-col--right border-none">
                            <h4>Rights/Partner</h4>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="table-wrap bg-lightYellow">
                      <Col lg={10}>
                        <div>
                          <Row className="table-row table-row__list">
                            <Col
                              className="table-col table-col__title"
                              lg={2.4}
                            >
                              <h5>PR004 (APG777)</h5>
                            </Col>
                            <Col className="table-col table-bar" lg={3.2}>
                              <div className="table-progress table-progress--full"></div>
                              <h4 className="table-col__heading">Clinical</h4>
                            </Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                          </Row>
                          <Row className="table-row table-row__list">
                            <Col
                              className="table-col table-col__title"
                              lg={2.4}
                            >
                              <h5>PR001 (APG808)</h5>
                            </Col>
                            <Col className="table-col table-bar" lg={3.2}>
                              <div className="table-progress table-progress--90"></div>
                              <h4 className="table-col__heading">Clinical</h4>
                            </Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                          </Row>
                          <Row className="table-row table-row__list">
                            <Col
                              className="table-col table-col__title"
                              lg={2.4}
                            >
                              <h5>PR007 (APG990)</h5>
                            </Col>
                            <Col className="table-col table-bar" lg={3.2}>
                              <div className="table-progress table-progress--90"></div>
                              <h4 className="table-col__heading">Clinical</h4>
                            </Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                          </Row>
                          <Row className="table-row table-row__list">
                            <Col
                              className="table-col table-col__title"
                              lg={2.4}
                            >
                              <h5>PR012 (APG222)</h5>
                            </Col>
                            <Col className="table-col table-bar" lg={3.2}>
                              <div className="table-progress table-progress--twoFull"></div>
                              <h4 className="table-col__heading">
                                Ind-Enabling
                              </h4>
                            </Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                          </Row>
                          <Row className="table-row table-row__list">
                            <Col
                              className="table-col table-col__title"
                              lg={2.4}
                            >
                              <h5>PR018 (APG333)</h5>
                            </Col>
                            <Col className="table-col table-bar" lg={3.2}>
                              <div className="table-progress table-progress--oneFull"></div>
                              <h4 className="table-col__heading">Discovery</h4>
                            </Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                          </Row>
                        </div>
                      </Col>
                      <Col lg={2} className="aligned-center">
                        <Row className="table-row table-head for-mobile">
                          <Col className="table-col border-none">
                            <h4>Rights/Partner</h4>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="table-col table-col__img border-none">
                            <a
                              href="https://apogeetherapeutics.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={apogeeLogo}
                                width={150}
                                height={43}
                                alt="Apogee logo"
                                loading="lazy"
                              />
                            </a>
                          </Col>
                        </Row>
                        <div className="accordion-btn-wrap more">
                          <button
                            className="accordion-btn"
                            onClick={() => handleItemOpen(0)}
                          >
                            More info{" "}
                            <span className="icon plus-icon">
                              <img src={plusIcon} alt="icon" />
                            </span>
                          </button>
                        </div>
                        <div className="accordion-btn-wrap less">
                          <button
                            className="accordion-btn"
                            onClick={() => handleItemClose(0)}
                          >
                            Less info{" "}
                            <span className="icon plus-icon">
                              <img src={minusIcon} alt="icon" />
                            </span>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  <div className="accordion-content">
                    <p>
                      <strong>Apogee Therapeutics</strong> is the first company
                      to be formed around assets discovered by and licensed from
                      Paragon. Established in 2022, Apogee is advancing a
                      pipeline of potentially best-in-class product candidates
                      targeting clinically validated biology and
                      well-established development pathways to address the needs
                      of the millions of people living with immunological and
                      inflammatory disorders.
                    </p>
                  </div>
                </div>

                <div
                  className={`table-block modified ${
                    activeItem.includes(1) ? "show" : ""
                  }`}
                >
                  <Container className="mobile-bg">
                    <Row className="table-wrap">
                      <Col lg={10} className="for-mobile">
                        <Row className="table-row table-head">
                          <Col className="table-col" lg={2.4} xs={6}>
                            <h3>Programs</h3>
                          </Col>
                          <Col className="table-col mobile-none" lg={3.2}>
                            <h4>Discovery</h4>
                          </Col>
                          <Col className="table-col mobile-none" lg={3.2}>
                            <h4>Ind-Enabling</h4>
                          </Col>
                          <Col className="table-col mobile-none" lg={3.2}>
                            <h4>Clinical</h4>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={2}>
                        <Row className="table-row table-head d-none">
                          <Col className="table-col table-col--right border-none">
                            <h4>Rights/Partner</h4>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="table-wrap bg-lightYellow">
                      <Col lg={10}>
                        <div>
                          <Row className="table-row table-row__list">
                            <Col
                              className="table-col table-col__title"
                              lg={2.4}
                            >
                              <h5>PR011 (SPY001)</h5>
                            </Col>
                            <Col className="table-col table-bar" lg={3.2}>
                              <div className="table-progress table-progress--twoFirst"></div>
                              <h4 className="table-col__heading">Clinical</h4>
                            </Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                          </Row>
                          <Row className="table-row table-row__list">
                            <Col
                              className="table-col table-col__title"
                              lg={2.4}
                            >
                              <h5>PR010 (SPY002)</h5>
                            </Col>
                            <Col className="table-col table-bar" lg={3.2}>
                              <div className="table-progress table-progress--twoFull"></div>
                              <h4 className="table-col__heading">
                                Ind-Enabling
                              </h4>
                            </Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                          </Row>
                          <Row className="table-row table-row__list">
                            <Col
                              className="table-col table-col__title"
                              lg={2.4}
                            >
                              <h5>PR014 (SPY003)</h5>
                            </Col>
                            <Col className="table-col table-bar" lg={3.2}>
                              <div className="table-progress table-progress--twoHalf"></div>
                              <h4 className="table-col__heading">
                                Ind-Enabling
                              </h4>
                            </Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                          </Row>
                          {/*<Row className="table-row table-row__list">
                            <Col
                              className="table-col table-col__title"
                              lg={2.4}
                            >
                              <h5>PR015 (SPY004)</h5>
                            </Col>
                            <Col className="table-col table-bar" lg={3.2}>
                              <div className="table-progress table-progress--oneHalf"></div>
                              <h4 className="table-col__heading">Discovery</h4>
                            </Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                          </Row>*/}
                          <Row className="table-row table-row__list">
                            <Col
                              className="table-col table-col__title"
                              lg={2.4}
                            >
                              <h5>PR026 (SPY120)</h5>
                            </Col>
                            <Col className="table-col table-bar" lg={3.2}>
                              <div className="table-progress table-progress--twoFull"></div>
                              <h4 className="table-col__heading">
                                Ind-Enabling
                              </h4>
                            </Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                          </Row>
                          <Row className="table-row table-row__list">
                            <Col
                              className="table-col table-col__title"
                              lg={2.4}
                            >
                              <h5>PR022 (SPY130)</h5>
                            </Col>
                            <Col className="table-col table-bar" lg={3.2}>
                              <div className="table-progress table-progress--twoHalf"></div>
                              <h4 className="table-col__heading">
                                Ind-Enabling
                              </h4>
                            </Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                          </Row>
                          <Row className="table-row table-row__list">
                            <Col
                              className="table-col table-col__title"
                              lg={2.4}
                            >
                              <h5>PR025 (SPY230)</h5>
                            </Col>
                            <Col className="table-col table-bar" lg={3.2}>
                              <div className="table-progress table-progress--twoHalf"></div>
                              <h4 className="table-col__heading">
                                Ind-Enabling
                              </h4>
                            </Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                          </Row>
                        </div>
                      </Col>
                      <Col lg={2} className="aligned-center">
                        <Row className="table-row table-head for-mobile">
                          <Col className="table-col border-none">
                            <h4>Rights/Partner</h4>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="table-col table-col__img border-none">
                            <a
                              href="https://spyretx.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={spyreLogo}
                                width={193}
                                height={47}
                                alt="Spyre Logo"
                                loading="lazy"
                              />
                            </a>
                          </Col>
                        </Row>
                        <div className="accordion-btn-wrap more">
                          <button
                            className="accordion-btn"
                            onClick={() => handleItemOpen(1)}
                          >
                            More info{" "}
                            <span className="icon plus-icon">
                              <img src={plusIcon} alt="icon" />
                            </span>
                          </button>
                        </div>
                        <div className="accordion-btn-wrap less">
                          <button
                            className="accordion-btn"
                            onClick={() => handleItemClose(1)}
                          >
                            Less info{" "}
                            <span className="icon plus-icon">
                              <img src={minusIcon} alt="icon" />
                            </span>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  <div className="accordion-content">
                    <p>
                      <strong>Spyre Therapeutics</strong> is the second company
                      to be formed around assets discovered by and licensed from
                      Paragon. Established in 2023, Spyre is advancing a
                      pipeline of product candidates that maximize the efficacy,
                      safety, and convenience of treatments for inflammatory
                      bowel disease (IBD), combining best-in-class antibody
                      engineering, rational therapeutic combinations, and
                      precision immunology approaches to potentially transform
                      the treatment paradigm.
                    </p>
                  </div>
                </div>

                <div
                  className={`table-block modified ${
                    activeItem.includes(2) ? "show" : ""
                  }`}
                >
                  <Container className="mobile-bg">
                    <Row className="table-wrap">
                      <Col lg={10} className="for-mobile">
                        <Row className="table-row table-head">
                          <Col className="table-col" lg={2.4} xs={6}>
                            <h3>Programs</h3>
                          </Col>
                          <Col className="table-col mobile-none" lg={3.2}>
                            <h4>Discovery</h4>
                          </Col>
                          <Col className="table-col mobile-none" lg={3.2}>
                            <h4>Ind-Enabling</h4>
                          </Col>
                          <Col className="table-col mobile-none" lg={3.2}>
                            <h4>Clinical</h4>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={2}>
                        <Row className="table-row table-head d-none">
                          <Col className="table-col table-col--right border-none">
                            <h4>Rights/Partner</h4>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="table-wrap bg-lightYellow">
                      <Col lg={10}>
                        <div>
                          <Row className="table-row table-row__list">
                            <Col
                              className="table-col table-col__title"
                              lg={2.4}
                            >
                              <h5>
                                PR035 <nobr>(ORKA-001)</nobr>
                              </h5>
                            </Col>
                            <Col className="table-col table-bar" lg={3.2}>
                              <div className="table-progress table-progress--85"></div>
                              <h4 className="table-col__heading">
                                Ind-Enabling
                              </h4>
                            </Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                          </Row>
                          <Row className="table-row table-row__list">
                            <Col
                              className="table-col table-col__title"
                              lg={2.4}
                            >
                              <h5>
                                PR029 <nobr>(ORKA-002)</nobr>
                              </h5>
                            </Col>
                            <Col className="table-col table-bar" lg={3.2}>
                              <div className="table-progress table-progress--twoHalf"></div>
                              <h4 className="table-col__heading">
                                Ind-Enabling
                              </h4>
                            </Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                          </Row>
                          <Row className="table-row table-row__list">
                            <Col
                              className="table-col table-col__title"
                              lg={2.4}
                            >
                              <h5>
                                PR059 <nobr>(ORKA-003)</nobr>
                              </h5>
                            </Col>
                            <Col className="table-col table-bar" lg={3.2}>
                              <div className="table-progress table-progress--oneFull"></div>
                              <h4 className="table-col__heading">Discovery</h4>
                            </Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                          </Row>
                        </div>
                      </Col>
                      <Col lg={2} className="aligned-center">
                        <Row className="table-row table-head for-mobile">
                          <Col className="table-col border-none">
                            <h4>Rights/Partner</h4>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="table-col table-col__img border-none mb-xxl-18 mw-xxl-10">
                            <a
                              href="https://orukatx.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={orukaFullLogo}
                                width={275}
                                height={93}
                                alt="Oruka Logo"
                                loading="lazy"
                              />
                            </a>
                          </Col>
                        </Row>
                        <div className="accordion-btn-wrap more bottom-0">
                          <button
                            className="accordion-btn"
                            onClick={() => handleItemOpen(2)}
                          >
                            More info{" "}
                            <span className="icon plus-icon">
                              <img src={plusIcon} alt="icon" />
                            </span>
                          </button>
                        </div>
                        <div className="accordion-btn-wrap less bottom-0">
                          <button
                            className="accordion-btn"
                            onClick={() => handleItemClose(2)}
                          >
                            Less info{" "}
                            <span className="icon plus-icon">
                              <img src={minusIcon} alt="icon" />
                            </span>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  <div className="accordion-content">
                    <p>
                      <strong>Oruka Therapeutics</strong> is the third company
                      to be formed around assets discovered by and licensed from
                      Paragon. Established in 2024, Oruka is advancing a
                      pipeline of potentially best-in-class biologics that aim
                      to redefine the standard of care for patients with chronic
                      skin diseases, including plaque psoriasis.
                    </p>
                  </div>
                </div>

                <div
                  className={`table-block modified ${
                    activeItem.includes(3) ? "show" : ""
                  }`}
                >
                  <Container className="mobile-bg">
                    <Row className="table-wrap">
                      <Col lg={10} className="for-mobile">
                        <Row className="table-row table-head">
                          <Col className="table-col" lg={2.4} xs={6}>
                            <h3>Programs</h3>
                          </Col>
                          <Col className="table-col mobile-none" lg={3.2}>
                            <h4>Discovery</h4>
                          </Col>
                          <Col className="table-col mobile-none" lg={3.2}>
                            <h4>Ind-Enabling</h4>
                          </Col>
                          <Col className="table-col mobile-none" lg={3.2}>
                            <h4>Clinical</h4>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={2}>
                        <Row className="table-row table-head d-none">
                          <Col className="table-col table-col--right border-none">
                            <h4>Rights/Partner</h4>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="table-wrap bg-lightYellow">
                      <Col lg={10}>
                        <div>
                          <Row className="table-row table-row__list">
                            <Col
                              className="table-col table-col__title"
                              lg={2.4}
                            >
                              <h5>
                                PR030 <nobr>(JADE-001)</nobr>
                              </h5>
                            </Col>
                            <Col className="table-col table-bar" lg={3.2}>
                              <div className="table-progress table-progress--oneFull"></div>
                              <h4 className="table-col__heading">Discovery</h4>
                            </Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                          </Row>
                          <Row className="table-row table-row__list">
                            <Col
                              className="table-col table-col__title"
                              lg={2.4}
                            >
                              <h5>
                                PR041 <nobr>(JADE-002)</nobr>
                              </h5>
                            </Col>
                            <Col className="table-col table-bar" lg={3.2}>
                              <div className="table-progress table-progress--oneHalf"></div>
                              <h4 className="table-col__heading">Discovery</h4>
                            </Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                          </Row>
                          <Row className="table-row table-row__list">
                            <Col
                              className="table-col table-col__title"
                              lg={2.4}
                            >
                              <h5>
                                PR050 <nobr>(JADE-003)</nobr>
                              </h5>
                            </Col>
                            <Col className="table-col table-bar" lg={3.2}>
                              <div className="table-progress table-progress--oneHalf"></div>
                              <h4 className="table-col__heading">Discovery</h4>
                            </Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                          </Row>
                        </div>
                      </Col>
                      <Col lg={2} className="aligned-center">
                        <Row className="table-row table-head for-mobile">
                          <Col className="table-col border-none">
                            <h4>Rights/Partner</h4>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="table-col table-col__img border-none mb-xxl-18 mw-xxl-118">
                            <a
                              href="https://jadebiosciences.com/"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={jadeBiosciencesLogo}
                                width={215}
                                height={99}
                                alt="Jade Biosciences Logo"
                                loading="lazy"
                              />
                            </a>
                          </Col>
                        </Row>
                        <div className="accordion-btn-wrap more bottom-0">
                          <button
                            className="accordion-btn"
                            onClick={() => handleItemOpen(3)}
                          >
                            More info{" "}
                            <span className="icon plus-icon">
                              <img src={plusIcon} alt="icon" />
                            </span>
                          </button>
                        </div>
                        <div className="accordion-btn-wrap less bottom-0">
                          <button
                            className="accordion-btn"
                            onClick={() => handleItemClose(3)}
                          >
                            Less info{" "}
                            <span className="icon plus-icon">
                              <img src={minusIcon} alt="icon" />
                            </span>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  <div className="accordion-content">
                    <p>
                      <strong>Jade Biosciences</strong> is the fourth company to
                      be formed around assets discovered by and licensed from
                      Paragon. Jade is developing a pipeline of potentially
                      best-in-class therapies to redefine the standard of care
                      for patients living with autoimmune conditions.
                    </p>
                  </div>
                </div>

                <div
                  className={`table-block modified ${
                    activeItem.includes(4) ? "show" : ""
                  }`}
                >
                  <Container className="mobile-bg">
                    <Row className="table-wrap">
                      <Col lg={10} className="for-mobile">
                        <Row className="table-row table-head">
                          <Col className="table-col" lg={2.4} xs={6}>
                            <h3>Programs</h3>
                          </Col>
                          <Col className="table-col mobile-none" lg={3.2}>
                            <h4>Discovery</h4>
                          </Col>
                          <Col className="table-col mobile-none" lg={3.2}>
                            <h4>Ind-Enabling</h4>
                          </Col>
                          <Col className="table-col mobile-none" lg={3.2}>
                            <h4>Clinical</h4>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={2}>
                        <Row className="table-row table-head d-none">
                          <Col className="table-col table-col--right border-none">
                            <h4>Rights/Partner</h4>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="table-wrap bg-lightYellow">
                      <Col lg={10}>
                        <div>
                          <Row className="table-row table-row__list">
                            <Col
                              className="table-col table-col__title"
                              lg={2.4}
                            >
                              <h5>
                                PR054 <nobr>(CR-001)</nobr>
                              </h5>
                            </Col>
                            <Col className="table-col table-bar" lg={3.2}>
                              <div className="table-progress table-progress--oneFull"></div>
                              <h4 className="table-col__heading">Discovery</h4>
                            </Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                          </Row>
                          <Row className="table-row table-row__list">
                            <Col
                              className="table-col table-col__title"
                              lg={2.4}
                            >
                              <h5>
                                PR062 <nobr>(CR-002)</nobr>
                              </h5>
                            </Col>
                            <Col className="table-col table-bar" lg={3.2}>
                              <div className="table-progress table-progress--oneHalf"></div>
                              <h4 className="table-col__heading">Discovery</h4>
                            </Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                          </Row>
                          <Row className="table-row table-row__list">
                            <Col
                              className="table-col table-col__title"
                              lg={2.4}
                            >
                              <h5>
                                PR023 <nobr>(CR-003)</nobr>
                              </h5>
                            </Col>
                            <Col className="table-col table-bar" lg={3.2}>
                              <div className="table-progress table-progress--oneHalf"></div>
                              <h4 className="table-col__heading">Discovery</h4>
                            </Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                          </Row>
                        </div>
                      </Col>
                      <Col lg={2} className="aligned-center">
                        <Row className="table-row table-head for-mobile">
                          <Col className="table-col border-none">
                            <h4>Rights/Partner</h4>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="table-col table-col__img border-none mb-xxl-18 max-w-134">
                            <a
                              href="https://www.crescentbiopharma.com"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={crescentLogo}
                                width={463}
                                height={168}
                                alt="Crescent Biopharma Logo"
                                loading="lazy"
                              />
                            </a>
                          </Col>
                        </Row>
                        <div className="accordion-btn-wrap more bottom-0">
                          <button
                            className="accordion-btn"
                            onClick={() => handleItemOpen(4)}
                          >
                            More info{" "}
                            <span className="icon plus-icon">
                              <img src={plusIcon} alt="icon" />
                            </span>
                          </button>
                        </div>
                        <div className="accordion-btn-wrap less bottom-0">
                          <button
                            className="accordion-btn"
                            onClick={() => handleItemClose(4)}
                          >
                            Less info{" "}
                            <span className="icon plus-icon">
                              <img src={minusIcon} alt="icon" />
                            </span>
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                  <div className="accordion-content">
                    <p>
                      <strong>Crescent Biopharma</strong> is the fifth company
                      to be formed around assets discovered by and licensed from
                      Paragon. Crescent is advancing a pipeline of
                      precision-engineered molecules designed to advance care
                      for patients with solid tumors.
                    </p>
                  </div>
                </div>

                <div className="table-block modified">
                  <Container className="mobile-bg">
                    <Row className="table-wrap">
                      <Col lg={10} className="for-mobile">
                        <Row className="table-row table-head">
                          <Col className="table-col" lg={2.4} xs={6}>
                            <h3>Programs</h3>
                          </Col>
                          <Col className="table-col mobile-none" lg={3.2}>
                            <h4>Discovery</h4>
                          </Col>
                          <Col className="table-col mobile-none" lg={3.2}>
                            <h4>Ind-Enabling</h4>
                          </Col>
                          <Col className="table-col mobile-none" lg={3.2}>
                            <h4>Clinical</h4>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={2}>
                        <Row className="table-row table-head d-none">
                          <Col className="table-col table-col--right border-none">
                            <h4>Rights/Partner</h4>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="table-wrap bg-lightYellow">
                      <Col lg={10}>
                        <div>
                          <Row className="table-row table-row__list">
                            <Col
                              className="table-col table-col__title"
                              lg={2.4}
                            >
                              <h5>PR002</h5>
                            </Col>
                            <Col className="table-col table-bar" lg={3.2}>
                              <div className="table-progress table-progress--twoHalfAlt"></div>
                              <h4 className="table-col__heading">
                                Ind-Enabling
                              </h4>
                            </Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                          </Row>
                          <Row className="table-row table-row__list">
                            <Col
                              className="table-col table-col__title"
                              lg={2.4}
                            >
                              <h5>PR013</h5>
                            </Col>
                            <Col className="table-col table-bar" lg={3.2}>
                              <div className="table-progress table-progress--oneFull"></div>
                              <h4 className="table-col__heading">Discovery</h4>
                            </Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                            <Col
                              className="table-col mobile-none"
                              lg={3.2}
                            ></Col>
                          </Row>
                        </div>
                      </Col>
                      <Col lg={2} className="aligned-center">
                        <Row className="table-row table-head for-mobile">
                          <Col className="table-col border-none">
                            <h4>Rights/Partner</h4>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="table-col table-col__img border-none">
                            <h6>Undisclosed</h6>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                </div>

                <div className="table-block modified">
                  <Container className="mobile-bg">
                    <Row className="table-wrap">
                      <Col lg={10} className="for-mobile">
                        <Row className="table-row table-head">
                          <Col className="table-col" lg={2.4} xs={6}>
                            <h3>Programs</h3>
                          </Col>
                          <Col className="table-col mobile-none" lg={3.2}>
                            <h4>Discovery</h4>
                          </Col>
                          <Col className="table-col mobile-none" lg={3.2}>
                            <h4>Ind-Enabling</h4>
                          </Col>
                          <Col className="table-col mobile-none" lg={3.2}>
                            <h4>Clinical</h4>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={2}>
                        <Row className="table-row table-head d-none">
                          <Col className="table-col table-col--right border-none">
                            <h4>Rights/Partner</h4>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="table-wrap bg-lightYellow">
                      <Col lg={10}>
                        <div>
                          <Row className="table-row table-row__list">
                            <Col
                              className="table-col table-col__title"
                              lg={2.4}
                            >
                              <h5 className="desktop-none">
                                Multiple internal programs
                              </h5>
                            </Col>
                            <Col lg={9.6} style={{}}>
                              <Row>
                                <Col className="table-col table-bar" lg={4}>
                                  <div className="table-progress table-progress--60"></div>
                                  <h4 className="table-col__heading">
                                    Discovery
                                  </h4>
                                </Col>
                                <Col
                                  className="table-col mobile-none"
                                  lg={4}
                                ></Col>
                                <Col
                                  className="table-col mobile-none"
                                  lg={4}
                                ></Col>
                              </Row>
                              <Row>
                                <Col className="table-col table-bar" lg={4}>
                                  <div className="table-progress table-progress--oneHalf"></div>
                                  <h4 className="table-col__heading">
                                    Discovery
                                  </h4>
                                </Col>
                                <Col
                                  className="table-col mobile-none"
                                  lg={4}
                                ></Col>
                                <Col
                                  className="table-col mobile-none"
                                  lg={4}
                                ></Col>
                              </Row>
                              <Row>
                                <Col className="table-col table-bar" lg={4}>
                                  <div className="table-progress table-progress--20"></div>
                                  <h4 className="table-col__heading">
                                    Discovery
                                  </h4>
                                </Col>
                                <Col
                                  className="table-col mobile-none"
                                  lg={4}
                                ></Col>
                                <Col
                                  className="table-col mobile-none"
                                  lg={4}
                                ></Col>
                              </Row>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                      <Col lg={2} className="aligned-center">
                        <Row className="table-row table-head for-mobile">
                          <Col className="table-col border-none">
                            <h4>Rights/Partner</h4>
                          </Col>
                        </Row>
                        <Row>
                          <Col className="table-col table-col__img border-none">
                            <img
                              src={paragonLogo}
                              width={350}
                              height={97}
                              alt="Paragon logo"
                              loading="lazy"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            </Container>
          </FluidContent>
        </div>

        <div id="partners">
          <FluidContent className="block-spinouts">
            <Container>
              <Row>
                <Col lg={6} ref={fadeToRefs}>
                  <ContentWrap
                    className="title-block"
                    contentSubHeading="<h3>Partners</h3>"
                    contentHeading="<h2>Driving rapid progress and patient impact.</h2>"
                  />
                </Col>
                <Col lg={6} ref={fadeToRefs}>
                  <ContentWrap
                    className="margin-inline"
                    contentPara="<p>Our scientific approach and expertise have generated the assets that serve as the foundation for several successful companies devoted to bringing transformative treatments to patients living with significant unmet medical conditions.</p>"
                  />
                </Col>
              </Row>
              <div className="card-block-wrapper">
                <Row justify="center">
                  <Col lg={6} style={{ marginBottom: 30 }}>
                    <div className="card-spinouts">
                      <div className="media">
                        <img
                          src={apogeeLargeLogo}
                          width={2421}
                          height={700}
                          alt="Apogee Logo"
                          loading="lazy"
                        />
                      </div>
                      <div className="card-spinouts__info">
                        <p className="bold">
                          <strong>ABOUT APOGEE THERAPEUTICS</strong>
                        </p>
                        <p>
                          Apogee Therapeutics is a biotechnology company
                          advancing novel, potentially best-in-class therapies
                          to address the needs of the millions of people living
                          with immunological and inflammatory disorders. Apogee
                          is building a pipeline of product candidates targeting
                          clinically validated biology and well-established
                          development pathways. Based in San Francisco, CA,
                          Apogee was founded in 2022 by Fairmount and Venrock
                          Healthcare Capital Partners and is backed by leading
                          healthcare investors. Visit their{" "}
                          <a
                            href="https://apogeetherapeutics.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            website
                          </a>{" "}
                          for more details. Follow Apogee on{" "}
                          <a
                            href="https://www.linkedin.com/company/apogee-therapeutics/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            LinkedIn
                          </a>
                          .{" "}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} style={{ marginBottom: 30 }}>
                    <div className="card-spinouts">
                      <div className="media">
                        <img
                          src={spyreLargeLogo}
                          width={583}
                          height={142}
                          alt="Spyre Logo"
                          loading="lazy"
                        />
                      </div>
                      <div className="card-spinouts__info">
                        <p className="bold">
                          <strong>ABOUT SPYRE THERAPEUTICS</strong>
                        </p>
                        <p>
                          Spyre Therapeutics is a biotechnology company
                          advancing a robust pipeline of antibody therapeutics
                          with the potential to transform the treatment of
                          inflammatory bowel disease (IBD). Spyre combines
                          best-in-class antibody engineering, rational
                          therapeutic combinations, and precision immunology
                          approaches to maximize efficacy, safety, and
                          convenience of treatments for IBD. The company was
                          founded in 2023 by Fairmount and is based in Waltham,
                          MA. Visit their{" "}
                          <a
                            href="https://spyretx.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            website
                          </a>{" "}
                          for more details. Follow Spyre on{" "}
                          <a
                            href="https://www.linkedin.com/company/spyre-therapeutics/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            LinkedIn
                          </a>
                          .{" "}
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} style={{ marginBottom: 30 }}>
                    <div className="card-spinouts card-spinouts--alt">
                      <div className="media">
                        <img
                          src={orukaFullLogo}
                          width={275}
                          height={93}
                          alt="Oruka Logo"
                          loading="lazy"
                        />
                      </div>
                      <div className="card-spinouts__info">
                        <p className="bold">
                          <strong>ABOUT ORUKA THERAPEUTICS</strong>
                        </p>
                        <p>
                          Oruka Therapeutics is developing novel biologics
                          designed to set a new standard for the treatment of
                          chronic skin diseases. Oruka’s mission is to offer
                          patients suffering from chronic skin diseases like
                          plaque psoriasis the greatest possible freedom from
                          their condition by achieving high rates of complete
                          disease clearance with dosing as infrequently as once
                          or twice a year. Oruka is advancing a proprietary
                          portfolio of potentially best-in-class antibodies that
                          were licensed from Paragon Therapeutics and target the
                          core mechanisms underlying plaque psoriasis and other
                          dermatologic and inflammatory diseases. Visit their{" "}
                          <a
                            href="https://www.orukatx.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            website
                          </a>{" "}
                          for more details. Follow Oruka on{" "}
                          <a
                            href="https://www.linkedin.com/company/oruka-therapeutics/posts/?feedView=all"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            LinkedIn
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} style={{ marginBottom: 30 }}>
                    <div className="card-spinouts card-spinouts--alt">
                      <div className="media">
                        <img
                          src={jadeBiosciencesLogo}
                          width={215}
                          height={99}
                          alt="Jade Biosciences Logo"
                          loading="lazy"
                        />
                      </div>
                      <div className="card-spinouts__info">
                        <p className="bold">
                          <strong>ABOUT JADE BIOSCIENCES</strong>
                        </p>
                        <p>
                          Jade Biosciences is developing transformative
                          therapies with the goal of redefining the standard of
                          care for autoimmune diseases. The company launched in
                          July 2024 and has raised $300 million from a strong
                          slate of investors: Fairmount, Venrock Healthcare
                          Capital Partners, Deep Track Capital, Driehaus Capital
                          Management, Franklin Templeton, RTW Investments,
                          Braidwell LP, Frazier Life Sciences, and Versant
                          Ventures. Jade’s portfolio was licensed from Paragon
                          Therapeutics. Visit their{" "}
                          <a
                            href="https://jadebiosciences.com/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            website
                          </a>{" "}
                          for more details. Follow Jade on{" "}
                          <a
                            href="https://www.linkedin.com/company/jade-biosciences/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            LinkedIn
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  </Col>
                  <Col lg={6} style={{ marginBottom: 30 }}>
                    <div className="card-spinouts card-spinouts--alt min-h-740">
                      <div className="media max-w-242">
                        <img
                          src={crescentLogo}
                          width={463}
                          height={168}
                          alt="Crescent Biopharma Logo"
                          loading="lazy"
                        />
                      </div>
                      <div className="card-spinouts__info">
                        <p className="bold">
                          <strong>ABOUT CRESCENT BIOPHARMA</strong>
                        </p>
                        <p>
                          Crescent BioPharma is a biotechnology company
                          dedicated to advancing novel precision-engineered
                          molecules targeting validated biology to advance care
                          for patients with solid tumors. The company’s pipeline
                          of three programs harness proven biology to accelerate
                          the path to market for potentially best in class
                          therapeutics. Visit their{" "}
                          <a
                            href="https://www.crescentbiopharma.com"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            website
                          </a>{" "}
                          for more details. Follow Crescent on{" "}
                          <a
                            href="https://www.linkedin.com/company/crescent-biopharma/"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            LinkedIn
                          </a>
                          .
                        </p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Container>
          </FluidContent>
        </div>

        <div id="team">
          <FluidContent className="block-team">
            <Container>
              <Row>
                <Col lg={6} ref={fadeToRefs}>
                  <ContentWrap
                    className="title-block title-block--alt"
                    contentSubHeading="<h3>Team</h3>"
                    contentHeading="<h2>Dedicated experts with a passion for improving health outcomes.</h2>"
                  />
                </Col>
                <Col lg={6} ref={fadeToRefs}>
                  <ContentWrap
                    className="text-white margin-inline"
                    contentPara="<p>Paragon is led by a collaborative team of innovators with deep expertise in biologics engineering and development. Together, we are motivated to tackle some of the toughest disease areas and improve quality of life for patients.</p>"
                  />
                </Col>
              </Row>

              <div className="card-team__block">
                <Row className="row">
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={susannaHeadshot}
                      teamImgAlt="Susanna High Headshot"
                      teamName="Susanna High, MBA"
                      designation="Chief Executive Officer"
                      teamBtn="View Bio"
                      description="<p>Susanna High is Chief Executive Officer at Paragon. She most recently served as Chief Operating Officer at Dyne Therapeutics, a clinical-stage company focused on developing life-transformative therapies for neuro-muscular diseases leveraging their proprietary antibody-conjugate platform. At Dyne, High contributed to bringing two programs onto their current path for global registrations, helped grow the organization five-fold, and played a role in a very successful IPO and more than $1B in subsequent raises. She previously served as Chief Operating Officer of bluebird bio, where she led the company’s severe genetic disease franchise and established its European organization. Prior to that, High was at Alnylam Pharmaceuticals for 10 years, with roles of increasing responsibility—most recently as Senior Vice President, Strategy and Business Integration, where she helped establish the company’s robust and pioneering RNAi therapeutics platform, grow the organization, and advance multiple products towards registration and launch preparation. Earlier in her career, she held positions in corporate strategy and operations at Millennium Pharmaceuticals (now Takeda Oncology) and in management consulting in the pharmaceutical practice of Accenture. High holds an M.S. in economics and business management from Università Bocconi and an M.B.A. from the MIT Sloan School of Management.</p>"
                    ></TeamDetails>
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={thompsonHeadshot}
                      teamImgAlt="Evan Thompson Headshot"
                      teamName="Evan Thompson, Ph.D."
                      designation="CHIEF OPERATING OFFICER"
                      teamBtn="View Bio"
                      description="<p>Evan Thompson is Chief Operating Officer at Paragon Therapeutics. He is a seasoned biotech executive with more than 20 years of experience in company formation and drug development. He was previously the Chief Operating Officer of Dianthus Therapeutics and serves as an advisor to Abcuro, a biotech company developing treatments for autoimmune diseases and cancer. He previously founded and co-founded kT Life Science Consulting, a firm that provides strategic analysis to biotech companies and investors, and Theraxen, a biotech advancing alternative nucleic acid therapeutics for rare and infectious diseases. Evan holds a Ph.D. in Biology from The Johns Hopkins University and completed his postdoctoral training at the National Institutes of Health and the Massachusetts Institute of Technology.</p>"
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={shaheenHeadshot}
                      teamImgAlt="Hussam Shaheen Headshot"
                      teamName="Hussam Shaheen, Ph.D."
                      designation="HEAD OF RESEARCH"
                      teamBtn="View Bio"
                      description="<p>Hussam Shaheen is Head of Research at Paragon Therapeutics. Prior to joining Paragon, he spent over 15 years driving antibody discovery and engineering through various roles at Merck and Pandion Therapeutics. Hussam completed his Ph.D. in Biochemistry and Molecular Biology at Pennsylvania State University and his postdoctoral training at Ohio State University.</p>"
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={lantzHeadshot}
                      teamImgAlt="Keri Lantz Headshot"
                      teamName="Keri Lantz"
                      designation="HEAD OF FINANCE"
                      teamBtn="View Bio"
                      description="Keri is the Head of Finance at Paragon Therapeutics. She has over 15 years of experience building and leading finance and administrative functions at both public and private biotech companies. Most recently Keri was the Chief Financial Officer at ValenzaBio until it was acquired by ACELYRIN. Prior to ValenzaBio, Keri was Vice President Finance and Corporate Controller at BioXcel Therapeutics, Head of Finance Operations at Alexion Pharmaceuticals and Vice President Finance and Corporate Controller at Achillion Pharmaceuticals, until it was acquired by Alexion. Keri has been responsible for internal and external financial reporting,internal controls, compliance, treasury, tax and FP&A. She has also overseen and managed Human Resources and IT functions. Additionally, Keri has led numerous due diligence, acquisition, and public and private offering activities. Keri is a certified public accountant and has a B.S. in Accounting from Central Connecticut State University."
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={banksHeadshot}
                      teamImgAlt="Damon Banks Headshot"
                      teamName="Damon Banks, Ph.D., J.D."
                      designation="HEAD OF LEGAL"
                      teamBtn="View Bio"
                      description="Damon Banks is Head of Legal at Paragon Therapeutics. He brings nearly 20 years of experience advising numerous biotechnology companies as both in-house and outside counsel. Prior to joining Paragon, Damon served as General Counsel at Alloy Therapeutics. He also served as Vice President, Legal Affairs at Inozyme Pharma and Compass Therapeutics. Damon earned a J.D. from The George Washington University Law School as well as a M.Phil. and Ph.D. in Microbiology from Yale University."
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={ghassanHeadshot}
                      teamImgAlt="Ghassan Fayad Headshot"
                      teamName="Ghassan Fayad, Ph.D."
                      designation="Vice President, Head of Drug Metabolism and Pharmacokinetics"
                      teamBtn="View Bio"
                      description="Dr. Ghassan Fayad is the Vice President of Drug Metabolism and Pharmacokinetics (DMPK) at Paragon Therapeutics. with extensive experience in pharmaceutical development. Over 14 years at Merck Research Labs, he engaged in every stage of drug development, from early discovery through late-stage clinical testing. Dr. Fayad specializes in preclinical and clinical characterization, pharmacokinetic-pharmacodynamic (PKPD) modeling, biomarker identification, bioanalysis, and regulatory writing for Investigational New Drug (IND) and Biologics License Application (BLA) submissions. He has successfully managed a diverse portfolio of research projects, ensuring compliance and strategic oversight in preclinical and clinical regulatory submissions. Dr. Fayad earned his Ph.D. in Mechanical Engineering from the Massachusetts Institute of Technology."
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={ohHeadshot}
                      teamImgAlt="Jason Oh Headshot"
                      teamName="Jason Oh, Ph.D."
                      designation="SENIOR VICE PRESIDENT OF BIOLOGY"
                      teamBtn="View Bio"
                      description="<p>Jason Oh is Senior Vice President of Biology at at Paragon Therapeutics. He brings over 15 years of experience advancing novel research and drug discovery at academic institutions and pharmaceutical companies to the team. Prior to joining Paragon, Jason led discovery programs and research groups in immunology and IO at Merck and AbbVie. Jason earned his Ph.D. in Immunology at the University of Colorado Denver and completed his postdoctoral training at Emory University.</p>"
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={russellHeadshot}
                      teamImgAlt="Shawn Russell Headshot"
                      teamName="Shawn Russell, Ph.D."
                      designation="SENIOR VICE PRESIDENT OF CHEMISTRY, MANUFACTURING, AND CONTROLS"
                      teamBtn="View Bio"
                      description="<p>Shawn Russell is Senior Vice President of Chemistry, Manufacturing, and Controls at Paragon Therapeutics. He has nearly 20 years of industry experience driving product development from pre-clinical through commercial programs across several organizations, including TG Therapeutics, Five Prime Therapeutics, and Genzyme. Shawn holds a Ph.D. in Chemical Engineering from the University of Virginia.</p>"
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={batschaHeadshot}
                      teamImgAlt="Neta Batscha Headshot"
                      teamName="Neta Batscha"
                      designation="SENIOR VICE PRESIDENT OF CORPORATE STRATEGY AND OPERATIONS"
                      teamBtn="View Bio"
                      description="<p>Neta Batscha is Senior Vice President of Corporate Strategy and Operations at Paragon Therapeutics. She has deep expertise guiding investment strategy and company formation across the biotech and life sciences sectors, previously holding various roles at RA Capital Management where she also served as Board Observer for several private and public biotech companies. Neta also previously led underwriting of biotech investments at The Beneficient Company Group. Neta holds a B.S. in Biology from the Massachusetts Institute of Technology.</p>"
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={meehlHeadshot}
                      teamImgAlt="Mike Meehl Headshot"
                      teamName="Michael Meehl, Ph.D."
                      designation="SENIOR VICE PRESIDENT OF BIOLOGICAL RESEARCH"
                      teamBtn="View Bio"
                      description="Michael Meehl is Senior Vice President of Biologics Research at Paragon Therapeutics. His expertise in the biotechnology industry is concentrated on advancing discovery and preclinical pipelines for biologics programs, including antibody discovery, protein engineering, production, and analytics. He has more than 15 years of experience in the industry, including Vice President of Protein Sciences and Research Informatics at Jounce Therapeutics, Director of Discovery Biologics Partnerships at Merck Research Labs, and Principal Scientist at GlycoFi. He graduated with a Ph.D. in Molecular Microbiology from Washington University in St. Louis and completed postdoctoral training at Dartmouth College School of Medicine. "
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={staceyHeadshot}
                      teamImgAlt="Cyrus Stacey Headshot"
                      teamName="Cyrus Stacey"
                      designation="VICE PRESIDENT OF QUALITY"
                      teamBtn="View Bio"
                      description="<p>Cyrus Stacey is Vice President of Quality at Paragon Therapeutics. He has nearly 20 years of industry experience providing quality oversight for early- to late-stage clinical development programs and commercial manufacturing across several organizations, including TG Therapeutics, Eisai, and Curacyte. Cyrus holds an M.S. from North Carolina State University.</p>"
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={joeHeadshot}
                      teamImgAlt="Joe Russo Headshot"
                      teamName="Joe Russo"
                      designation="EXECUTIVE DIRECTOR OF FINANCE PLANNING AND ANALYSIS"
                      teamBtn="View Bio"
                      description="<p>Joe Russo is Executive Director of Financial Planning and Analysis at Paragon Therapeutics. He has nearly 20 years of experience working for emerging U.S. based and international biotech companies. Joe was most recently Vice President of FP&A for Provention Bio until its acquisition by Sanofi. He has held prior accounting and finance leadership roles at several organizations, including DBV Technologies, Radius Health, and Veloxis Pharmaceuticals. Joe earned his MBA from Penn State University.</p>"
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={shahHeadshot}
                      teamImgAlt="Archit Sheth-Shah Headshot"
                      teamName="Archit Sheth-Shah"
                      designation="SENIOR DIRECTOR OF PORTFOLIO STRATEGY"
                      teamBtn="View Bio"
                      description="Archit Sheth-Shah is Senior Director of Portfolio Strategy at Paragon Therapeutics. He has more than a decade of experience in the biopharma industry across investing, operating, and advisory roles. Previously, he served as Chief Business Officer at Enable Medicine, a spatial proteomics startup out of Stanford, was a Principal at Roivant Sciences focused on oncology and immunology asset in-licensing and company creation, and started his career serving large pharma and private equity clients at Bain & Company. Archit obtained an MBA and an MS in Bioengineering from Stanford University."
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={kwanHeadshot}
                      teamImgAlt="Byron Kwan Headshot"
                      teamName="Byron Kwan, Ph.D."
                      designation="DIRECTOR OF BIOTHERAPEUTICS"
                      teamBtn="View Bio"
                      description="<p>Byron Kwan is Director of Biotherapeutics at Paragon Therapeutics. He brings more than seven years of biotech industry experience in the discovery and development of therapeutic antibodies and antibody-drug candidates to the Paragon team. He previously served as a Principal Research Scientist and Product Team Lead at Seagen, leading and collaborating with cross-functional matrix teams spanning research, translational sciences, development, clinical, regulatory, and commercial to advance drug candidates from discovery to IND. Byron earned his Ph.D. in Biological Engineering at the Massachusetts Institute of Technology.</p>"
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={bernardHeadshot}
                      teamImgAlt="Bernard Liu Headshot"
                      teamName="Bernard Liu, Ph.D."
                      designation="DIRECTOR OF BIOTHERAPEUTICS"
                      teamBtn="View Bio"
                      description="<p>Bernard Liu is Director of Biotherapeutics at Paragon Therapeutics. He brings over 12 years of biotech experience developing novel molecular entities in oncology from small molecules, biologics, to antibody drug conjugates (ADCs).   Prior to joining Paragon, Bernard led multiple programs from early discovery to FDA approvals at both Seagen/Pfizer and Gilead Sciences. He has also held roles at SUGEN, Abbvie, the Broad Institute of MIT and Harvard.</p><p>Bernard earned his Ph.D. in Cancer Biology from the University of Chicago.</p>"
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={danielleHeadshot}
                      teamImgAlt="Danielle Mallay Headshot"
                      teamName="Danielle Malloy"
                      designation="DIRECTOR OF LEGAL OPERATIONS"
                      teamBtn="View Bio"
                      description="<p>Danielle Malloy is the Director of Legal Operations at Paragon Therapeutics. She has more than 16 years of experience in the biotechnology and life sciences industry from start-ups through late clinical phase private and public companies. Prior to joining Paragon, she served as Associate Director, Business and Legal Operations at AlloVir, Inc. Her expertise includes legal operations, and all phases of contract lifecycle management. Danielle is a certified Paralegal and received her advanced certification from Sam Houston State University. Additionally, Danielle received her Biopharmaceutical, Biomanufacturing Technical Certification from the National Center for Therapeutics Manufacturing at Texas A&M University.</p>"
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={riosHeadshot}
                      teamImgAlt="Daniel Rios Headshot"
                      teamName="Daniel Rios, Ph.D."
                      designation="DIRECTOR OF BIOLOGY"
                      teamBtn="View Bio"
                      description="<p>Daniel Rios is Director of Biology at Paragon Therapeutics. He brings over 12 years of experience in biomedicine. Prior to joining Paragon, Daniel served as a Principal Scientist at ROME Therapeutics and as a Senior Scientist at Pandion Therapeutics. Daniel began his career as a graduate student at Emory University before becoming a Scholar in Residence at the Novartis Institutes for BioMedical Research (NIBR). He holds a B.S. in Biology/Biological Sciences from Brandeis University.</p>"
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={pamelaHeadshot}
                      teamImgAlt="Pamela Howley Headshot"
                      teamName="Pamela Howley"
                      designation="DIRECTOR OF CHEMISTRY, MANUFACTURING, AND CONTROLS"
                      teamBtn="View Bio"
                      description="<p>Pamela Howley is Director of Chemistry, Manufacturing, and Controls at Paragon Therapeutics. She has more than 10 years of experience working within the pharmaceutical and medical device industries. Pamela’s expertise includes quality control, corrective and preventive action (CAPA), ELISA, and protein chemistry. Pamela has a M.S. in Biotechnology and a MBA focused in Biosecurity and Biodefense from University of Maryland Global Campus.</p>"
                    />
                  </Col>

                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={schirmer}
                      teamImgAlt="Andrew Schirmer Headshot"
                      teamName="Andrew Schirmer"
                      designation="Associate Director of Manufacturing "
                      teamBtn="View Bio"
                      description="<p>Andrew Schirmer is the Associate Director of Manufacturing at Paragon Therapeutics. He has over 20 years of research and development experience, specializing in mammalian cell culture for therapeutic protein expression and cell products, medium and feed development, process transfer, and scaled-down modeling. He previously worked as an R&D Principal Scientist for Catalent Pharma Solutions, where he improved HEK bank processes across multiple sites, including a new controlled rate freezer protocol, coordinated platform development for new cell line technology, and led client project work for non-platform cell lines and proteins.

                      Andrew has a Master’s degree in Chemistry from the University of Virginia.
                      </p>"
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={alamHeadshot}
                      teamImgAlt="M. Murshid AlamAlam Headshot"
                      teamName="M. Murshid Alam, Ph.D."
                      designation="PRINCIPAL SCIENTIST"
                      teamBtn="View Bio"
                      description="<p>Mohammad Murshid Alam is Principal Scientist of Biology at Paragon Therapeutics. He has extensive experience in biomedical research and drug discovery in diverse areas including immuno-oncology, infectious disease immunology, and vaccines. Prior to joining Paragon, Murshid led drug discovery programs and research groups in immuno-&#x2060;oncology at Jounce Therapeutics. He earned his Ph.D. in Chemistry (Chemical Biology) from the Massachusetts Institute of Technology.</p>"
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={clarkHeadshot}
                      teamImgAlt="Erin Clark Headshot"
                      teamName="Erin Clark, Ph.D."
                      designation="PRINCIPAL SCIENTIST"
                      teamBtn="View Bio"
                      description="<p>Erin Clark is Principal Scientist at Paragon Therapeutics. She brings over 5 years of experience in drug discovery. She has extensive expertise in molecular and cellular biology, in vitro and in vivo pharmacology, and neurobiology. Prior to joining Paragon, she was a Principal Scientist at Sumitomo Pharma America and Sunovion Pharmaceuticals where she led two early phase programs.</p><p>Erin has a Ph.D. in Biochemistry, Cellular & Molecular Pharmacology from Harvard Medical School.</p>"
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={kangHeadshot}
                      teamImgAlt="Byong Ha Kang Headshot"
                      teamName="Byong Ha Kang, Ph.D."
                      designation="PRINCIPAL SCIENTIST"
                      teamBtn="View Bio"
                      description="<p>Byong Ha Kang is Principal Scientist at Paragon Therapeutics. He was previously a scientist at Asher Biotherapeutics, isolating and optimizing antibody candidates to support the company’s pipeline of highly selective immunotherapies. Byong earned his Ph.D. in Biological Engineering from the Massachusetts Institute of Technology.</p>"
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={milliganHeadshot}
                      teamImgAlt="Jake Milligan Headshot"
                      teamName="Jake Milligan, Ph.D."
                      designation="PRINCIPAL SCIENTIST"
                      teamBtn="View Bio"
                      description="<p>Jake Milligan is Principal Scientist at Paragon Therapeutics. He was previously a Principal Scientist at Pfizer where he served as the protein engineering team leader for several early discovery programs in immuno-oncology, a role which included therapeutic protein design, production, and characterization. Jake holds his Ph.D. in Biological Sciences from the University of California Irvine and completed his postdoctoral training at The Scripps Research Institute and La Jolla Institute for Immunology.</p>"
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={kramerHeadshot}
                      teamImgAlt="Kevin Kramer Headshot"
                      teamName="Kevin Kramer, Ph.D."
                      designation="Senior Scientist"
                      teamBtn="View Bio"
                      description="Kevin Kramer is a Senior Scientist at Paragon Therapeutics. In his previous role at Sanofi, he led several antibody discovery programs supporting inflammation and immunology pipeline targets serving as a biologics program lead. He also established NGS-based discovery technologies to maximize antibody sequence diversity and developability during clone selection, resulting in expedited lead candidate nomination. Kevin earned a Ph.D. in Immunology from Vanderbilt University."
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={maslinHeadshot}
                      teamImgAlt="Lauren Maslin Headshot"
                      teamName="Lauren Maslin, Ph.D."
                      designation="SENIOR ASSOCIATE"
                      teamBtn="View Bio"
                      description="<p>Lauren Maslin is Senior Associate at Paragon Therapeutics. Prior to joining Paragon, she served as an Associate at Third Rock Ventures, where she focused on NewCo ideation to translate innovative science and technologies into novel therapeutics. She began her career at Catalio Capital, where she worked as an Analyst and led scientific and investment diligence on biotech companies with a focus in the oncology and immuno-oncology space.</p><p>Lauren earned her Ph.D. in Cellular and Molecular Medicine from Johns Hopkins School of Medicine.</p>"
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={vazHeadshot}
                      teamImgAlt="Rita Vaz Headshot"
                      teamName="Rita Vaz, Ph.D."
                      designation="SENIOR DIRECTOR OF PROGRAM AND ALLIANCE MANAGEMENT"
                      teamBtn="View Bio"
                      description="<p>Rita Vaz is Senior Director of Program and Alliance Management at Paragon Therapeutics. She previously applied her expertise in drug and antibody development through roles of increasing responsibility at leading biotech companies including FairJourney Biologics, IONTAS, and TechnoPhage. She has also provided counsel as a biotech consultant at ETECLAB. Rita earned her M.S. in Applied Microbiology and Ph.D. in Neuroscience from the University of Lisbon.</p>"
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={oliveiraHeadshot}
                      teamImgAlt="Soraia Oliveira Headshot"
                      teamName="Soraia Oliveira, Ph.D."
                      designation="Senior Project Manager for Antibody Discovery and Development"
                      teamBtn="View Bio"
                      description="<p>Soraia Oliveira is Senior Project Manager for Antibody Discovery and Development at Paragon Therapeutics. She has deep experience in antibody engineering and manufacturing, and previously served as a Project Manager at ETECLAB, a consulting firm specializing information technologies and drug discovery and development, and TechnoPhage. Prior to joining the biotech sector, Soraia was an Assistant Professor of immunology as part of the Faculty of Pharmacy of the University of Lisbon. Soraia holds a M.S. in Molecular Biology and Genetics and a Ph.D. in Pharmacy from the University of Lisbon.</p>"
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={ministroHeadshot}
                      teamImgAlt="Joana Ministro Headshot"
                      teamName="Joana Ministro, Ph.D."
                      designation="SENIOR PROJECT MANAGER FOR ANTIBODY DISCOVERY AND DEVELOPMENT"
                      teamBtn="View Bio"
                      description="<p>Joana Ministro is Senior Project Manager at Paragon Therapeutics. She brings more than 10 years of experience in research and development of biologics. Prior to joining Paragon, Joana served as a senior scientist at LeanBio S.L. Joana holds a Ph.D. in Pharmaceutical Biotechnology from the University of Lisbon.</p>"
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={vidalHeadshot}
                      teamImgAlt="Joana Vidal Silva Headshot"
                      teamName="Joana Vidal Silva"
                      designation="PROJECT MANAGER FOR ANTIBODY DISCOVERY AND DEVELOPMENT"
                      teamBtn="View Bio"
                      description="Joana Vidal Silva is a Project Manager for Antibody Discovery and Development at Paragon Therapeutics. She has more than 6 years of experience in antibody discovery and engineering. She previously served as a team lead for FairJourney Biologics, where she oversaw advancement of 20+ discovery and engineering projects. Joana holds a M.S. in Bioengineering from Faculdade de Engenharia da Universidade do Porto."
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={castroHeadshot}
                      teamImgAlt="Inês Castro Headshot"
                      teamName="Inês Castro, Ph.D."
                      designation="Project Manager for Antibody Discovery and Development"
                      teamBtn="View Bio"
                      description="Inês Castro is a Project Manager for Antibody Discovery and Development at Paragon Therapeutics. Prior to joining Paragon, she served as Head of Portfolio and Partnership Management at 272BIO (UK), where her responsibilities included strategic scientific leadership, management of antibody discovery projects, external collaborations and partnership management, and operational activities. Earlier in her career, she was a Senior Scientist at a leading CRO specializing in the discovery of therapeutic monoclonal antibodies. Inês holds a Ph.D. in Molecular Medicine from the MRC Toxicology Unit in Leicester (UK) and was awarded a Postdoctoral Fellowship at the Gulbenkian Institute in Lisbon (PT)."
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={markHeadshot}
                      teamImgAlt="Mark Heffernan Headshot"
                      teamName="Mark Heffernan"
                      designation="SENIOR DIRECTOR AND CORPORATE CONTROLLER"
                      teamBtn="View Bio"
                      description="<p>Mark Heffernan is a Senior Director and Corporate Controller at Paragon Therapeutics. He has more than 10 years of experience working for both public and private biotech companies, and most recently was the Controller at Codagenix. Mark's expertise includes monthly closings, financial statement preparation, SOX/internal controls, GAAP research and implementation, financial analysis, budgeting, audit support, and ERP implementations. Mark is a certified public accountant and has a M.S. in taxation from the University of New Haven.</p>"
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={melissa}
                      teamImgAlt="Melissa Donnarummo Headshot"
                      teamName="Melissa Donnarummo"
                      designation="Director of Project Accounting "
                      teamBtn="View Bio"
                      description="<p>Melissa Donnarummo is the Director of Project Accounting at Paragon Therapeutics. She has over 20 years of accounting experience in biotech. She previously worked as an Associate Director for ValenzaBio which has now been acquired by ACELYRIN, Inc. She has experience in collaborating with cross-functional teams for preclinical operations, budget tracking and preparation.</p><p>Melissa has a Bachelor of Accounting degree from Bryant University.</p>"
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={rayeHeadshot}
                      teamImgAlt="Jodie Raye Headshot"
                      teamName="Jodie Raye"
                      designation="SENIOR ACCOUNTANT"
                      teamBtn="View Bio"
                      description="<p>Jodie Raye is Senior Accountant at Paragon Therapeutics. She brings over 5 years of experience in accounting and expertise in management reporting, budgeting, forecasting and strategic planning. Prior to joining Paragon Therapeutics, Jodie was the Senior Financial Analyst of Clinical Operations at Replimune.</p><p>Jodie has a B.S. in Accounting from Fitchburg State University.</p>"
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={pohleHeadshot}
                      teamImgAlt="Eric Pohle Headshot"
                      teamName="Eric Pohle"
                      designation="ASSOCIATE DIRECTOR OF FINANCIAL PLANNING AND ANALYSIS"
                      teamBtn="View Bio"
                      description="<p>Eric Pohle is an Associate Director of Financial Planning and Analysis at Paragon Therapeutics. He has over 8 years of experience in various industries. Most recently, Eric was a Strategic Finance Manager at CFGI. Here he helped clients by developing and implementing budgeting processes, revamping management reporting, and optimizing their use of Enterprise Performance Management tools. Prior to CFGI, he worked at Payoneer and PwC. Eric is a licensed CPA in the state of New York and is a graduate of Penn State University.</p>"
                    />
                  </Col>

                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={igoeHeadshot}
                      teamImgAlt="Jill Igoe Headshot"
                      teamName="Jill Igoe"
                      designation="ACCOUNTS PAYABLE MANAGER"
                      teamBtn="View Bio"
                      description="<p>Jill Igoe is Account Payable Manager at Paragon Therapeutics. She has over 25 years of experience in invoices, clerical support, records management, auditing. Jill has experience in IFS, Oracle, Great Plains and Microsoft Office. She is also a Notary. Prior to joining Paragon, she was the Accounts Payable Manager at Garvin Construction Products.</p><p>Jill has an Accounting background from Middlesex College and Northeastern University.</p>"
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={gillisHeadshot}
                      teamImgAlt="Lauren Gillis Headshot"
                      teamName="Lauren Gillis"
                      designation="DIRECTOR OF EXECUTIVE ADMINSTRATION"
                      teamBtn="View Bio"
                      description="Lauren Gillis is Director of Executive Administration at Paragon Therapeutics. Prior to joining Paragon, she served as Manager of Executive Administration and Senior Executive Assistant to the President and CEO at Agenus and Chief of Staff at Dialogos International. Earlier in her career, Lauren held producer roles at local and national sports broadcasting networks. Lauren holds an M.S. in Journalism from Quinnipiac University."
                    />
                  </Col>

                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={medinaHeadshot}
                      teamImgAlt="Sophia Medina Headshot"
                      teamName="Sophia Medina"
                      designation="ADMINISTRATIVE ASSISTANT"
                      teamBtn="View Bio"
                      description="<p>Sophia Medina is Administrative Assistant at Paragon Therapeutics. In this position, Sophia works closely with senior leaders providing administrative assistance and logistical support. Sophia holds a B.S. in Psychology from the University of Central Florida.</p>"
                    />
                  </Col>
                  <Col lg={3} sm={6} className="col" ref={fadeToRefs}>
                    <TeamDetails
                      teamHeadshot={fioreHeadshot}
                      teamImgAlt="Alison Fiore Headshot"
                      teamName="Alison Fiore"
                      designation="ADMINISTRATIVE ASSISTANT"
                      teamBtn="View Bio"
                      description="<p>Alison Fiore is an Administrative Assistant at Paragon Therapeutics. She brings over 10 years of administrative and operational experience to the role. Prior to joining Paragon, she was an Executive Assistant at Silversmith Capital Partners where she provided direct support to senior leaders of the firm through calendar management, travel coordination, and event planning. Prior to Silversmith, Alison gained administrative experience in both marketing and financial services settings. Alison holds a BFA in Fashion Design from Syracuse University.</p>"
                    />
                  </Col>
                </Row>
              </div>

              <div className="mini-banner bg-cover" ref={fadeToRefs}>
                <img
                  src={patternBg}
                  width="1630"
                  height="410"
                  loading="lazy"
                  alt="About us"
                  className="pattern-bg"
                />
                <img
                  src={patternBgMobile}
                  loading="lazy"
                  alt="About us"
                  className="pattern-bg-mobile"
                />
                <Row align="center" className="mini-banner__row">
                  <Col lg={6} className="text-center">
                    <img
                      src={fairmountLogo}
                      width="405"
                      height="98"
                      loading="lazy"
                      alt="Fairmount"
                    />
                  </Col>
                  <Col lg={6}>
                    <ContentWrap
                      className="title-block"
                      contentSubHeading="<h3>About Us</h3>"
                      contentPara="<p>Paragon was founded by Fairmount in 2021 as the firm’s discovery engine for best-in-class biologics and complex biologics. Leveraging a dedicated in-house team of scientific experts in antibody development, Paragon pursues unique therapeutic concepts and enables their rapid proof-of-concept validation. Fairmount is dedicated to investing in promising new therapies developed by biotechnology and life science companies.</p>"
                    />
                  </Col>
                </Row>
              </div>
            </Container>
          </FluidContent>
        </div>

        <div id="news">
          <FluidContent className="block-news">
            <Container>
              <div
                className="section-title"
                ref={fadeToRefs}
                style={{ maxWidth: 700 }}
              >
                <ContentWrap
                  className="title-block"
                  contentSubHeading="<h3>News</h3>"
                  contentHeading="<h2>The latest Paragon news and developments.</h2>"
                />
              </div>
              <AllBlogPosts />
              {/* <Row>
                <Col lg={6} ref={fadeToRefs}>
                  <ContentWrap
                    className="title-block"
                    contentSubHeading="<h3>News</h3>"
                    contentHeading="<h2>The latest Paragon news and developments.</h2>"
                  />
                </Col>
                <Col lg={6} className="margin-inline" ref={fadeToRefs}>
                  <RecentBlogPosts />
                </Col>
              </Row> */}
            </Container>
          </FluidContent>
        </div>

        <div id="join-us">
          <FluidContent className="bg-cover block-join">
            <img
              src={joinBg}
              height="715"
              width="1920"
              loading="lazy"
              alt="Join Us"
              className="joinbg"
            />

            <Container ref={fadeToRefs}>
              <ContentWrap
                className="title-block linked-in-block"
                contentSubHeading="<h3>Join Us</h3>"
                contentHeading="<h2>Be a part of our innovative team.</h2>"
                contentPara="<p>We are excited about the future as we advance our state-of-the-art science. Come join our team and help us shape the next generation of biologic treatments.</p>"
                linkText="VIEW POSITIONS ON LINKEDIN"
                linkUrl="https://www.linkedin.com/company/paragon-therapeutics-inc/jobs/"
                linkTarget="_blank"
              />
            </Container>
            <div className="block-values">
              <Container>
                <Row align="center">
                  <Col lg={6}>
                    <ContentWrap
                      className="title-block"
                      contentSubHeading="<h3>VALUES</h3>"
                      contentHeading="<h2>Powered by our dedication and commitment to improve the lives of patients with high unmet needs.</h2>"
                    />
                  </Col>
                  <Col lg={6}>
                    <ContentWrap
                      className="test-white"
                      contentPara="<p>Our values reflect who we are and guide our actions and decisions, empowering everyone to contribute meaningfully to our shared purpose.</p>"
                    />
                  </Col>
                </Row>
                <Row className="card-values-row">
                  <Col md={6} className="col">
                    <div className="card-values" ref={valuesRef2}>
                      <Row>
                        <Col lg={3.8}>
                          <div className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="145.22"
                              height="145.221"
                              viewBox="0 0 145.22 145.221"
                            >
                              <g id="icon_team-first" transform="translate(0)">
                                <path
                                  id="Path_1022"
                                  data-name="Path 1022"
                                  d="M82.5,114.649,31.23,1.406A.993.993,0,0,1,32.666.156L82.5,28.765,132.363.138A1,1,0,0,1,133.724.5a1.045,1.045,0,0,1,.088.782l-.044.126ZM33.327,2.309,82.5,110.918,131.669,2.311,82.5,30.54Zm-1.457-.837.036.02Zm101.266,0-.011.006.011-.006M32.627.763l.005.012L32.627.763"
                                  transform="translate(-9.886 -0.001)"
                                  fill="#d81712"
                                />
                                <path
                                  id="Path_1023"
                                  data-name="Path 1023"
                                  d="M132.856,159.441a.99.99,0,0,1-.533-.156L82.492,130.676,32.628,159.3a1,1,0,0,1-1.36-.356,1.046,1.046,0,0,1-.089-.784l.044-.126L82.492,44.792l51.27,113.244a.993.993,0,0,1-.69,1.381,1.009,1.009,0,0,1-.216.024m-.5-.775,0,.012Zm-100.493-.7-.011.007Zm101.225-.015.029.017a.205.205,0,0,0-.029-.017m-50.6-29.05,49.171,28.231L82.492,48.523,33.322,157.131Z"
                                  transform="translate(-9.886 -14.221)"
                                  fill="#fca265"
                                />
                                <path
                                  id="Path_1024"
                                  data-name="Path 1024"
                                  d="M158.46,133.852a1.193,1.193,0,0,1-.3-.038l-.126-.044L44.792,82.5,158.035,31.231a.993.993,0,0,1,1.25,1.437L130.675,82.5,159.3,132.363a1,1,0,0,1-.358,1.362.942.942,0,0,1-.485.127m-.493-.725.006.012ZM48.522,82.5,157.13,131.67,128.9,82.5l28.231-49.171ZM158.677,32.628l-.012.005.012-.005m-.709-.757a.383.383,0,0,0-.02.036Z"
                                  transform="translate(-14.22 -9.886)"
                                  fill="#ffad1d"
                                />
                                <rect
                                  id="Rectangle_3493"
                                  data-name="Rectangle 3493"
                                  width="10.292"
                                  height="1.538"
                                  transform="translate(100.404 40.998) rotate(-24.36)"
                                  fill="#ffad1d"
                                />
                                <path
                                  id="Path_1025"
                                  data-name="Path 1025"
                                  d="M.994,133.854a1,1,0,0,1-.839-1.53L28.764,82.493.136,32.63a1,1,0,0,1,.358-1.362,1.044,1.044,0,0,1,.783-.089l.126.044,113.245,51.27L1.4,133.762a.957.957,0,0,1-.41.092m.5-.769-.022.037c.008-.012.015-.025.022-.037m-.718-.727-.016.008ZM2.309,33.322,30.538,82.493,2.307,131.664,110.917,82.493Zm-.842-1.467.006.011-.006-.011"
                                  transform="translate(0 -9.887)"
                                  fill="#f96b0b"
                                />
                                <rect
                                  id="Rectangle_3494"
                                  data-name="Rectangle 3494"
                                  width="8.113"
                                  height="1.54"
                                  transform="translate(90.508 81.845) rotate(-24.349)"
                                  fill="#f96b0b"
                                />
                                <rect
                                  id="Rectangle_3495"
                                  data-name="Rectangle 3495"
                                  width="31.557"
                                  height="1.539"
                                  transform="matrix(0.412, -0.911, 0.911, 0.412, 48.381, 84.079)"
                                  fill="#fca265"
                                />
                                <rect
                                  id="Rectangle_3496"
                                  data-name="Rectangle 3496"
                                  width="1.539"
                                  height="65.555"
                                  transform="matrix(0.911, -0.412, 0.412, 0.911, 21.344, 1.405)"
                                  fill="#d81712"
                                />
                                <rect
                                  id="Rectangle_3497"
                                  data-name="Rectangle 3497"
                                  width="1.539"
                                  height="11.962"
                                  transform="translate(51.896 68.887) rotate(-24.357)"
                                  fill="#d81712"
                                />
                                <rect
                                  id="Rectangle_3498"
                                  data-name="Rectangle 3498"
                                  width="1.539"
                                  height="9.431"
                                  transform="matrix(0.911, -0.412, 0.412, 0.911, 61.758, 90.671)"
                                  fill="#d81712"
                                />
                                <rect
                                  id="Rectangle_3499"
                                  data-name="Rectangle 3499"
                                  width="16.189"
                                  height="1.539"
                                  transform="matrix(0.412, -0.911, 0.911, 0.412, 71.912, 112.465)"
                                  fill="#d81712"
                                />
                                <rect
                                  id="Rectangle_3500"
                                  data-name="Rectangle 3500"
                                  width="11.005"
                                  height="1.539"
                                  transform="matrix(0.412, -0.911, 0.911, 0.412, 82.067, 90.036)"
                                  fill="#d81712"
                                />
                                <rect
                                  id="Rectangle_3501"
                                  data-name="Rectangle 3501"
                                  width="9.23"
                                  height="1.539"
                                  transform="matrix(0.412, -0.911, 0.911, 0.412, 91.929, 68.252)"
                                  fill="#d81712"
                                />
                                <rect
                                  id="Rectangle_3502"
                                  data-name="Rectangle 3502"
                                  width="1.539"
                                  height="6.993"
                                  transform="translate(78.878 48.158) rotate(-24.361)"
                                  fill="#fca265"
                                />
                                <rect
                                  id="Rectangle_3503"
                                  data-name="Rectangle 3503"
                                  width="9.144"
                                  height="1.539"
                                  transform="matrix(0.911, -0.412, 0.412, 0.911, 67.819, 55.75)"
                                  fill="#ffad1d"
                                />
                              </g>
                            </svg>
                          </div>
                        </Col>
                        <Col lg={8}>
                          <div className="info">
                            <h3 className="text-white">Team First</h3>
                            <p>
                              We respect and value each other’s ideas and
                              contributions. We achieve more by working
                              together.
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md={6} className="col">
                    <div className="card-values" ref={valuesRef3}>
                      <Row>
                        <Col lg={3.8}>
                          <div className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="140.432"
                              height="127.992"
                              viewBox="0 0 140.432 127.992"
                            >
                              <g
                                id="icon_uphold-high-bar"
                                transform="translate(0)"
                              >
                                <path
                                  id="Path_1003"
                                  data-name="Path 1003"
                                  d="M451.17,14.963H312.276a.77.77,0,0,1,0-1.539H451.17a.77.77,0,0,1,0,1.539"
                                  transform="translate(-311.507 -13.424)"
                                  fill="#f96b0b"
                                />
                                <path
                                  id="Path_1004"
                                  data-name="Path 1004"
                                  d="M363.094,147.622,311.826,34.378a.993.993,0,0,1,1.439-1.248l49.83,28.608,49.863-28.628a.984.984,0,0,1,.749-.1,1,1,0,0,1,.613.46,1.044,1.044,0,0,1,.088.782l-.044.126ZM313.924,35.283l49.171,108.608,49.17-108.607-49.17,28.229Zm-1.457-.837.038.023Zm101.267,0-.012.007.012-.007m-100.511-.707.007.015-.007-.015"
                                  transform="translate(-311.579 -19.63)"
                                  fill="#d81712"
                                />
                                <path
                                  id="Path_1005"
                                  data-name="Path 1005"
                                  d="M417.9,147.622,366.635,34.378a.993.993,0,0,1,1.439-1.248L417.9,61.739l49.863-28.628a.984.984,0,0,1,.749-.1.994.994,0,0,1,.613.46,1.045,1.045,0,0,1,.088.782l-.044.126ZM368.733,35.283,417.9,143.891l49.17-108.607L417.9,63.513Zm-1.457-.837.038.023Zm101.267,0-.012.007.012-.007m-100.511-.707.007.015-.007-.015"
                                  transform="translate(-328.979 -19.63)"
                                  fill="#ffad1d"
                                />
                                <rect
                                  id="Rectangle_3488"
                                  data-name="Rectangle 3488"
                                  width="50.291"
                                  height="1.538"
                                  transform="translate(57.763 38.517) rotate(-29.852)"
                                  fill="#d81712"
                                />
                                <rect
                                  id="Rectangle_3489"
                                  data-name="Rectangle 3489"
                                  width="65.544"
                                  height="1.539"
                                  transform="translate(50.814 125.809) rotate(-65.64)"
                                  fill="#d81712"
                                />
                              </g>
                            </svg>
                          </div>
                        </Col>
                        <Col lg={8}>
                          <div className="info">
                            <h3 className="text-white">Uphold a High Bar</h3>
                            <p>
                              We build best-in-class therapies that are
                              impactful for patients. We aim to improve with
                              every iteration, striving for long-term
                              excellence.
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md={6} className="col">
                    <div className="card-values" ref={valuesRef4}>
                      <Row>
                        <Col lg={3.8}>
                          <div className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="179.278"
                              height="139.152"
                              viewBox="0 0 179.278 139.152"
                            >
                              <g
                                id="icon_experimentalists-at-heart"
                                transform="translate(0)"
                              >
                                <path
                                  id="Path_1018"
                                  data-name="Path 1018"
                                  d="M748.856,118.269a.993.993,0,0,1-.534-.157L698.492,89.5l-49.864,28.627a1,1,0,0,1-1.362-.358,1.045,1.045,0,0,1-.088-.782l.044-.126L698.492,3.621l51.27,113.243a.993.993,0,0,1-.69,1.381,1.008,1.008,0,0,1-.216.024m-.5-.776.007.016Zm-100.492-.7-.01.006Zm101.218-.02.038.022a.357.357,0,0,0-.038-.022M698.492,87.73l49.171,28.23L698.492,7.352l-49.17,108.607Z"
                                  transform="translate(-608.856 -3.621)"
                                  fill="#d81712"
                                />
                                <path
                                  id="Path_1019"
                                  data-name="Path 1019"
                                  d="M748.856,118.269a.993.993,0,0,1-.534-.157L698.492,89.5l-49.864,28.627a1,1,0,0,1-1.362-.358,1.045,1.045,0,0,1-.088-.782l.044-.126L698.492,3.621l51.27,113.243a.993.993,0,0,1-.69,1.381,1.008,1.008,0,0,1-.216.024m-.5-.776.007.016Zm-100.492-.7-.01.006Zm101.218-.02.038.022a.357.357,0,0,0-.038-.022M698.492,87.73l49.171,28.23L698.492,7.352l-49.17,108.607Z"
                                  transform="translate(-608.856 -3.621)"
                                  fill="#d81712"
                                />
                                <path
                                  id="Path_1020"
                                  data-name="Path 1020"
                                  d="M673.179,170.839a1.1,1.1,0,0,1-.265-.033l-.137-.051-81.728-39.32,81.727-39.319a.909.909,0,0,1,1.2,1.244L657.1,131.435l16.872,38.084a.917.917,0,0,1-.351,1.2.879.879,0,0,1-.447.117m-78.721-39.4,77.563,37.317-16.532-37.317L672.021,94.12Z"
                                  transform="translate(-591.049 -31.687)"
                                  fill="#f96b0b"
                                />
                                <path
                                  id="Path_1021"
                                  data-name="Path 1021"
                                  d="M732.967,170.839a.868.868,0,0,1-.451-.12.915.915,0,0,1-.348-1.2l16.872-38.084L732.171,93.359a.91.91,0,0,1,1.2-1.245L815.1,131.435,733.231,170.8a1.053,1.053,0,0,1-.264.034m1.157-76.72,16.533,37.317-.132.3-16.4,37.018,77.564-37.317Z"
                                  transform="translate(-635.818 -31.687)"
                                  fill="#ffad1d"
                                />
                                <rect
                                  id="Rectangle_3491"
                                  data-name="Rectangle 3491"
                                  width="22.101"
                                  height="1.539"
                                  transform="translate(51.298 84.678) rotate(-65.64)"
                                  fill="#d81712"
                                />
                                <rect
                                  id="Rectangle_3492"
                                  data-name="Rectangle 3492"
                                  width="1.539"
                                  height="16.995"
                                  transform="translate(106.902 95.797) rotate(-60.14)"
                                  fill="#d81712"
                                />
                              </g>
                            </svg>
                          </div>
                        </Col>
                        <Col lg={8}>
                          <div className="info">
                            <h3 className="text-white">
                              Experimentalists at Heart
                            </h3>
                            <p>
                              We are open-minded and data-driven. We constantly
                              adapt and evolve as new information emerges.
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md={6} className="col">
                    <div className="card-values" ref={valuesRef5}>
                      <Row>
                        <Col lg={3.8}>
                          <div className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="154.272"
                              height="102.712"
                              viewBox="0 0 154.272 102.712"
                            >
                              <g
                                id="icon_bias-towards-action"
                                transform="translate(0)"
                              >
                                <path
                                  id="Path_1013"
                                  data-name="Path 1013"
                                  d="M1069.383,106.8H918.94a.769.769,0,0,1,0-1.538h150.443a.769.769,0,0,1,0,1.538"
                                  transform="translate(-918.171 -54.671)"
                                  fill="#f96b0b"
                                />
                                <path
                                  id="Path_1014"
                                  data-name="Path 1014"
                                  d="M977.22,133.853a1,1,0,0,1-.537-.157.995.995,0,0,1-.3-1.373l28.608-49.83L976.363,32.629a1,1,0,0,1,.358-1.362,1.042,1.042,0,0,1,.781-.088l.126.044,113.245,51.27L977.63,133.762a.961.961,0,0,1-.41.091m.5-.77-.022.039c.008-.013.015-.026.022-.039m-.719-.725-.015.008Zm1.536-99.035,28.229,49.17-28.231,49.17,108.608-49.17Zm-.844-1.471.008.015Z"
                                  transform="translate(-936.601 -31.141)"
                                  fill="#d81712"
                                />
                                <path
                                  id="Path_1015"
                                  data-name="Path 1015"
                                  d="M919.164,125.426a1,1,0,0,1-.535-.156.994.994,0,0,1-.3-1.373l.15-.171,39.972-33.9L918.379,55.83l-.066-.111a1,1,0,0,1,.354-1.37,1.043,1.043,0,0,1,.78-.089l114.092,35.56L919.519,125.36a1,1,0,0,1-.355.066m2.43-68.886,39.234,33.281L921.591,123.1l106.777-33.281Z"
                                  transform="translate(-918.171 -38.469)"
                                  fill="#ffad1d"
                                />
                                <rect
                                  id="Rectangle_3490"
                                  data-name="Rectangle 3490"
                                  width="1.538"
                                  height="18.526"
                                  transform="translate(54.185 26.612) rotate(-29.861)"
                                  fill="#d81712"
                                />
                                <path
                                  id="Path_1016"
                                  data-name="Path 1016"
                                  d="M1084.263,106.8H1046.49a.769.769,0,1,1,0-1.538h37.772a.769.769,0,1,1,0,1.538"
                                  transform="translate(-958.664 -54.671)"
                                  fill="#f96b0b"
                                />
                                <path
                                  id="Path_1017"
                                  data-name="Path 1017"
                                  d="M971.694,106.8H918.94a.769.769,0,0,1,0-1.538h52.753a.769.769,0,1,1,0,1.538"
                                  transform="translate(-918.171 -54.671)"
                                  fill="#f96b0b"
                                />
                              </g>
                            </svg>
                          </div>
                        </Col>
                        <Col lg={8}>
                          <div className="info">
                            <h3 className="text-white">Bias Toward Action</h3>
                            <p>
                              We work with a sense of urgency to advance
                              therapeutic programs to the clinic rapidly and
                              efficiently.
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col md={6} className="col">
                    <div className="card-values" ref={valuesRef6}>
                      <Row>
                        <Col lg={3.8}>
                          <div className="icon">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="157.647"
                              height="109.444"
                              viewBox="0 0 157.647 109.444"
                            >
                              <g id="icon_keep-it-fun" transform="translate(0)">
                                <path
                                  id="Path_1006"
                                  data-name="Path 1006"
                                  d="M1378.37,124.5a1,1,0,0,1-1-.994L1376.2,66.031l-50.234-27.969a.987.987,0,0,1-.417-1.312c.156-.31.565.584.9.548l.046.435a.541.541,0,0,0,.429-.3.547.547,0,0,0-.2-.711l50.994,28.39.009.442,1.183,57.912a.542.542,0,0,0-.16-.356.562.562,0,0,0-.384-.155.537.537,0,0,0-.365.13l.457.451c-.119.121.268.954-.077.957Z"
                                  transform="translate(-1255.451 -29.534)"
                                  fill="#f96b0b"
                                />
                                <path
                                  id="Path_1007"
                                  data-name="Path 1007"
                                  d="M1379.616,83.488a.826.826,0,0,1-.156-.016l-9.6-1.977a.769.769,0,1,1,.311-1.507l8.909,1.833,2.551-8.734a.768.768,0,0,1,.893-.538l8.915,1.831,2.554-8.736a.767.767,0,0,1,.893-.538l8.916,1.831,2.551-8.735a.769.769,0,0,1,.893-.538l9.609,1.974a.769.769,0,1,1-.309,1.507l-8.915-1.831-2.551,8.735a.769.769,0,0,1-.893.538l-8.915-1.831-2.554,8.737a.768.768,0,0,1-.893.537L1382.9,74.2l-2.551,8.734a.77.77,0,0,1-.738.554"
                                  transform="translate(-1269.356 -36.189)"
                                  fill="#ffad1d"
                                />
                                <path
                                  id="Path_1008"
                                  data-name="Path 1008"
                                  d="M1341.983,65.469a.77.77,0,0,1-.167-1.521l8.874-1.987-1.265-9.008a.77.77,0,0,1,.593-.858L1358.9,50.1l-1.263-9.011a.77.77,0,0,1,.593-.858l8.878-1.992-1.265-9.009a.77.77,0,0,1,.593-.858l9.57-2.147a.769.769,0,0,1,.337,1.5l-8.878,1.992,1.265,9.009a.77.77,0,0,1-.593.858l-8.878,1.993,1.263,9.011a.77.77,0,0,1-.593.858l-8.878,1.992,1.265,9.009a.769.769,0,0,1-.594.857l-9.566,2.143a.8.8,0,0,1-.169.018"
                                  transform="translate(-1260.458 -26.208)"
                                  fill="#ffad1d"
                                />
                                <path
                                  id="Path_1009"
                                  data-name="Path 1009"
                                  d="M1370.326,124.017a.769.769,0,0,1-.551-1.306l7.353-7.543a.77.77,0,0,1,.965-.111l8.353,5.326,6.91-7.095a.769.769,0,0,1,.965-.112l8.342,5.328,6.906-7.094a.768.768,0,0,1,.965-.112l8.344,5.327,6.9-7.094a.769.769,0,1,1,1.1,1.074l-7.342,7.542a.769.769,0,0,1-.965.112l-8.343-5.326-6.906,7.093a.771.771,0,0,1-.966.112l-8.341-5.327-6.91,7.094a.77.77,0,0,1-.965.112l-8.354-5.326-6.915,7.095a.768.768,0,0,1-.552.232"
                                  transform="translate(-1269.456 -52.587)"
                                  fill="#ffad1d"
                                />
                                <path
                                  id="Path_1010"
                                  data-name="Path 1010"
                                  d="M1323.1,146.534l-33.047-18.462-.887-38.674,1.538-.035.867,37.8,32.279,18.032Z"
                                  transform="translate(-1243.934 -46.258)"
                                  fill="#f96b0b"
                                />
                                <path
                                  id="Path_1011"
                                  data-name="Path 1011"
                                  d="M1271.643,167.076l-16.174-9.048-.422-18.978,1.538-.034.4,18.1,15.408,8.619Z"
                                  transform="translate(-1233.103 -62.021)"
                                  fill="#f96b0b"
                                />
                                <path
                                  id="Path_1012"
                                  data-name="Path 1012"
                                  d="M1223.665,138.86a.769.769,0,0,1-.633-1.206l69.643-100.911a1.028,1.028,0,0,1,.844-.431.993.993,0,0,1,.967,1.019l1.174,57.448,50.235,27.97a1,1,0,0,1,.4,1.351,1.04,1.04,0,0,1-.625.479.657.657,0,0,1-.131.027l-121.779,14.248a.662.662,0,0,1-.089.005m69.316-99.853-67.718,98.122,118.412-13.854L1294.525,95.91a.771.771,0,0,1-.4-.657Zm52.162,85.086.01.005Zm-51.2-86.483-.007.01.007-.01m-1-.283a.3.3,0,0,0,0,.035Z"
                                  transform="translate(-1222.896 -29.416)"
                                  fill="#d81712"
                                />
                              </g>
                            </svg>
                          </div>
                        </Col>
                        <Col lg={8}>
                          <div className="info">
                            <h3 className="text-white">Keep It Fun</h3>
                            <p>
                              We celebrate our successes and our opportunities
                              to learn. We are passionate about our work and our
                              play.
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
          </FluidContent>
        </div>

        <div id="contact">
          <FluidContent className="contact-block">
            <Container>
              <Row>
                <Col lg={6} ref={fadeToRefs}>
                  <ContentWrap
                    className="title-block title-block--alt"
                    contentSubHeading="<h3>Contact</h3>"
                    contentHeading="<h2>For media inquiries or more information:</h2>"
                  />
                </Col>
                <Col lg={6} ref={fadeToRefs}>
                  {submitted ? showThankYou : showForm}
                </Col>
              </Row>
            </Container>
          </FluidContent>
        </div>
      </main>
    </Layout>
  );
};

export default IndexPage;

export const Head = () => <Seo title="Home" />;
