import React from "react";

function CardIcon({
    cardHeading,
    cardDesc,
    imgRef,
    cardClass,
    imgAlt,
    ...props
}) {
    return (
        <article {...props} className={`card-icon ${cardClass ? cardClass : ""}`}>
            {imgRef ? (
                <div className="card-icon__media">
                    <img src={imgRef} alt={imgAlt} />
                </div>
            ) : ""}
            <div className="card-icon__desc">
                {cardHeading ? (
                    <div dangerouslySetInnerHTML={{ __html: cardHeading }} />
                ) : ""}
                {cardDesc ? (
                    <div dangerouslySetInnerHTML={{ __html: cardDesc }} />
                ) : ""}
            </div>
        </article>
    );
}


export default CardIcon;