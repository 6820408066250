import React from 'react'

const TabPanel = React.forwardRef(function TabPanel(props, ref) {
  const {
    children,
    value,
    index,
    tabControlName = 'simple-tabs',
    ...rest
  } = props

  return (
    <div
      {...rest}
      role='tabpanel'
      hidden={value !== index}
      id={`${tabControlName}-panel-${index}`}
      aria-labelledby={`${tabControlName}-tab-${index}`}
      ref={ref}
    >
      {children}
    </div>
  )
})

export default TabPanel